import { useEffect, useState, memo } from "react";
import { DateTime } from "luxon";
import { useDraggable } from "@dnd-kit/core";
import type { Feature } from "geojson";

import MissionService, { Mission, Status } from "../../service/mission.ts";

import {
  Button,
  Classes,
  HTMLTable,
  Intent,
  NonIdealState,
  NonIdealStateIconSize,
  PanelProps,
  PanelStack2,
  ProgressBar,
  SectionCard,
  Spinner,
  Tag,
} from "@blueprintjs/core";

export const MissionStatusTag = (props: { status: Status }) => {
  const intent: Record<Status, Intent> = {
    "In Progress": "success",
    Ordered: "warning",
    Rejected: "danger",
    Complete: "none",
  };
  return (
    <Tag minimal intent={intent[props.status]}>
      {props.status}
    </Tag>
  );
};

type MissionDetailPanelInfo = {
  mission: Mission;
};

export const MissionDetailPanel = (props: MissionDetailPanelInfo) => {
  const { mission } = props;
  return (
    <SectionCard padded>
      <div className="grid grid-cols-3 gap-y-2 text-xs">
        <div className="col-span-2">
          <span className={Classes.TEXT_MUTED}>Id</span>
          <p>{mission.id}</p>
        </div>
        <div>
          <span className="float-right">
            <MissionStatusTag status={mission.status} />
          </span>
        </div>
        <div className="col-span-3">
          <span className={Classes.TEXT_MUTED}>Ordered</span>
          <p>{DateTime.fromISO(mission.ordered).toLocaleString(DateTime.DATETIME_FULL)}</p>
        </div>
        <div className="col-span-3">
          <span className={Classes.TEXT_MUTED}>Started</span>
          <p>
            {mission.started
              ? DateTime.fromISO(mission.started).toLocaleString(DateTime.DATETIME_FULL)
              : "-"}
          </p>
        </div>
        <div className="col-span-3">
          <span className={Classes.TEXT_MUTED}>Finished</span>
          <p>
            {mission.finished
              ? DateTime.fromISO(mission.finished).toLocaleString(DateTime.DATETIME_FULL)
              : "-"}
          </p>
        </div>
        <div className="col-span-3">
          <span className={Classes.TEXT_MUTED}>Progress</span>
          <span>
            <ProgressBar
              value={mission.progress || 0.0}
              intent={mission.status == "In Progress" ? "success" : "none"}
              stripes={mission.status === "Ordered" || mission.status === "In Progress"}
            />
          </span>
        </div>
      </div>
    </SectionCard>
  );
};

type MissionTableRowProps = {
  mission: Mission;
  openDetails: (mission: Mission) => void;
};

const MissionTableRow = ({ mission, openDetails }: MissionTableRowProps) => {
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: mission.id,
    data: { kind: "mission", mission },
  });

  return (
    <tr>
      <td className="flex justify-between gap-x-3">
        <Button
          ref={setNodeRef}
          {...attributes}
          {...listeners}
          icon="drag-handle-vertical"
          minimal
          small
          className="cursor-grab"
        />
        <div onClick={() => openDetails(mission)} className="flex w-full justify-between">
          {mission.name}
          <MissionStatusTag status={mission.status} />
        </div>
      </td>
    </tr>
  );
};

type MissionListPanelInfo = {
  missions?: Mission[];
};

const MissionListPanel = (props: PanelProps<MissionListPanelInfo>) => {
  const { missions, openPanel } = props;

  const openDetails = (mission: Mission) => {
    openPanel({
      title: mission.name,
      props: { mission },
      renderPanel: MissionDetailPanel,
    });
  };

  return (
    <SectionCard className="h-fit" padded={false}>
      <HTMLTable striped compact interactive className="w-full">
        <thead></thead>
        <tbody>
          {missions?.map((mission) => (
            <MissionTableRow
              key={mission.id}
              mission={mission}
              openDetails={() => openDetails(mission)}
            />
          ))}
        </tbody>
      </HTMLTable>
    </SectionCard>
  );
};

const MissionPanel = memo(({ feature }: { feature: Feature }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState(undefined);
  const [missions, setMissions] = useState<Mission[]>();

  useEffect(() => {
    const { search } = MissionService;
    const stopLoading = () => setLoading(false);
    setLoading(true);
    search().then(setMissions).catch(setError).finally(stopLoading);
  }, [feature.id]);

  if (feature.properties?.sensor !== "drone") {
    return null;
  }

  if (loading) {
    return (
      <SectionCard className="h-64">
        <NonIdealState
          icon={<Spinner />}
          iconSize={NonIdealStateIconSize.SMALL}
          description={"Fetching mission data."}
        />
      </SectionCard>
    );
  }

  if (error) {
    return (
      <SectionCard className="h-64">
        <NonIdealState
          icon={"error"}
          iconSize={NonIdealStateIconSize.SMALL}
          description={"Failed to retrieve missions data."}
        />
      </SectionCard>
    );
  }

  if (missions?.length == 0) {
    return (
      <SectionCard className="h-64">
        <NonIdealState
          icon={"playbook"}
          iconSize={NonIdealStateIconSize.SMALL}
          description={"No active or recent missions."}
        />
      </SectionCard>
    );
  }

  return (
    // NB: Rendering issue with PanelStack2 inside SectionCard that requires padding=1 for border to render correctly.
    <SectionCard padded={false} className="p-[1px]">
      <PanelStack2
        className={"h-64"}
        initialPanel={{
          props: { missions },
          title: "Mission",
          renderPanel: MissionListPanel,
        }}
        showPanelHeader={true}
        renderActivePanelOnly={true}
      ></PanelStack2>
    </SectionCard>
  );
});

export default MissionPanel;
