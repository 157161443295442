import React from "react";
import { Classes } from "@blueprintjs/core";

export const StyleInjector = (props: {
  isLoading: boolean;
  children: React.ReactElement | React.ReactElement[];
}) => {
  const traverse = (children: React.ReactElement | React.ReactElement[]) => {
    return React.Children.map(children, (child) => {
      if (!React.isValidElement(child)) {
        return child;
      }

      // @ts-expect-error typescript is wrong here
      if (child.props.children) {
        child = React.cloneElement(child, {
          // @ts-expect-error typescript is wrong here
          children: traverse(child.props.children),
        });
      }

      if (child.type === "span" || child.type === "p" || child.type === "h5") {
        return React.cloneElement(child, {
          className: `${child.props.className} ${Classes.SKELETON} m-0.5`,
        });
      }

      return child;
    });
  };

  if (props.isLoading) return traverse(props.children);
  return props.children;
};
