import type { LayerProps } from "react-map-gl/maplibre";

export const submarine_cables: LayerProps = {
  id: "cables",
  minzoom: 1,
  type: "line",
  layout: {
    "line-join": "round",
    "line-cap": "round",
  },
  paint: {
    "line-color": ["get", "color"],
    "line-width": 1.5,
  },
};

export const submarine_cable_names: LayerProps = {
  id: "cablesnames",
  minzoom: 6,
  type: "symbol",
  layout: {
    "text-allow-overlap": true,
    "text-field": "{name}",
    "text-size": 12,
    "text-anchor": "bottom",
    "symbol-placement": "line",
  },
  paint: {
    "text-color": "#FFF",
  },
};
