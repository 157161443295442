import { useCallback, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useMap } from "react-map-gl";
import { useDebounce } from "usehooks-ts";
import { HotkeysTarget2, MenuItem } from "@blueprintjs/core";
import { Omnibar, type ItemRenderer } from "@blueprintjs/select";

import type { Point } from "geojson";

import useApplicationStore from "../stores/application";
import { get } from "../service/feature";
import { find } from "../service/static-characteristics";
import { VesselTypeToString } from "../util/ais";
import type { VesselCharacteristic } from "../stores/model";

const renderItems: ItemRenderer<VesselCharacteristic> = (vessel, props) => {
  if (!props.modifiers.matchesPredicate) return null;

  return (
    <MenuItem
      key={vessel.mmsi}
      onClick={props.handleClick}
      text={vessel.name}
      label={VesselTypeToString(vessel.type)}
    />
  );
};

const SearchOmnibar = () => {
  const map = useMap();
  const { setHooked, isOmnibarOpen, setIsOmnibarOpen } = useApplicationStore();

  const [filter, setFilter] = useState("");
  const debouncedFilter = useDebounce(filter, 500);
  const { data } = useQuery({
    queryKey: ["characteristics", filter],
    queryFn: async () => find(debouncedFilter, 1),
    enabled: Boolean(debouncedFilter),
  });

  const handleGoTo = useCallback(
    async (vessel: VesselCharacteristic) => {
      if (!map.current) return;
      const feature = await get("ais", vessel.mmsi);
      const [longitude, latitude] = (feature.geometry as Point).coordinates;
      setHooked(feature.id);
      setIsOmnibarOpen(false);
      map.current.panTo([longitude!, latitude!]);
    },
    [map, setHooked, setIsOmnibarOpen],
  );

  return (
    <HotkeysTarget2
      hotkeys={[
        {
          combo: "shift + space",
          global: true,
          label: "Show Search Omnibar",
          onKeyDown: () => setIsOmnibarOpen(true),
          preventDefault: true,
        },
      ]}
    >
      <Omnibar<VesselCharacteristic>
        query={filter}
        onQueryChange={setFilter}
        items={data ?? []}
        itemRenderer={renderItems}
        onItemSelect={handleGoTo}
        isOpen={isOmnibarOpen}
        onClose={() => setIsOmnibarOpen(false)}
        noResults={<MenuItem disabled={true} text="No results" />}
      />
    </HotkeysTarget2>
  );
};

export default SearchOmnibar;
