import { Tag, Tooltip } from "@blueprintjs/core";

interface VesselTagProps {
  type?: number;
}

const VesselTag = (props: VesselTagProps) => {
  switch (props.type) {
    case 21:
    case 41:
    case 61:
    case 71:
    case 81:
    case 91:
      return (
        <Tooltip content={"Category A"} compact>
          <Tag intent={"warning"} minimal>
            Hazardous
          </Tag>
        </Tooltip>
      );
    case 22:
    case 42:
    case 62:
    case 72:
    case 82:
    case 92:
      return (
        <Tooltip content={"Category B"} compact>
          <Tag intent={"warning"} minimal>
            Hazardous
          </Tag>
        </Tooltip>
      );
    case 23:
    case 43:
    case 63:
    case 73:
    case 83:
    case 93:
      return (
        <Tooltip content={"Category C"} compact>
          <Tag intent={"warning"} minimal>
            Hazardous
          </Tag>
        </Tooltip>
      );
    case 24:
    case 44:
    case 64:
    case 74:
    case 84:
    case 94:
      return (
        <Tooltip content={"Category D"} compact>
          <Tag intent={"warning"} minimal>
            Hazardous
          </Tag>
        </Tooltip>
      );
    case 55:
      return (
        <Tag intent={"primary"} minimal>
          Law Enforcement
        </Tag>
      );
    case 35:
      return (
        <Tag intent={"none"} minimal>
          Military
        </Tag>
      );
    default:
      return <></>;
  }
};

export default VesselTag;
