const symbols: Record<string, string> = {
  // ADS-B Symbology
  light: "light.png",
  small: "small.png",
  medium: "medium.png",
  heavy: "heavy.png",
  "high-performance": "high-performance.png",
  rotorcraft: "rotorcraft.png",
  "lighter-than-air": "lighter-than-air.png",
  ultralight: "ultralight.png",
  uav: "uav.png",
  "surface-vehicle": "surface-vehicle.png",

  // AIS Symbology Moving
  "unspecified-a": "unspecified-a.png",
  "wing-in-ground-a": "wing-in-ground-a.png",
  "sar-aircraft-a": "sar-aircraft-a.png",
  "fishing-a": "fishing-a.png",
  "tug-a": "tug-a.png",
  "special-a": "special-a.png",
  "pleasure-a": "pleasure-a.png",
  "high-speed-a": "high-speed-a.png",
  "sar-a": "sar-a.png",
  "passenger-a": "passenger-a.png",
  "cargo-a": "cargo-a.png",
  "tanker-a": "tanker-a.png",

  // AIS Symbology Stationary
  "unspecified-b": "unspecified-b.png",
  "wing-in-ground-b": "wing-in-ground-b.png",
  "sar-aircraft-b": "sar-aircraft-b.png",
  "fishing-b": "fishing-b.png",
  "tug-b": "tug-b.png",
  "special-b": "special-b.png",
  "pleasure-b": "pleasure-b.png",
  "high-speed-b": "high-speed-b.png",
  "sar-b": "sar-b.png",
  "passenger-b": "passenger-b.png",
  "cargo-b": "cargo-b.png",
  "tanker-b": "tanker-b.png",
};

export default symbols;
