import { DateTime } from "luxon";
import type { Feature, Point } from "geojson";
import { Callout, Classes, SectionCard } from "@blueprintjs/core";
import { StyleInjector } from "../loading.tsx";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

export const ViirsAfPanelHeader = () => {
    return (
        <Callout title="Fire Detected by VIIRS">
            <p>VIIRS</p>
        </Callout>
    );
};

type ViirsAfPanelProps = {
    feature: Feature;
    handleGoTo: () => void;
    isLoading: boolean;
};

export const ViirsAfDataPanel = (props: ViirsAfPanelProps) => {
    const { properties } = props.feature;
    const [longitude, latitude] = (props.feature.geometry as Point).coordinates;
    const reactQueryClient = new QueryClient();

    return (
        <QueryClientProvider client={reactQueryClient}>
            <StyleInjector isLoading={props.isLoading}>
                <SectionCard className="grid grid-cols-3 gap-y-2 text-xs">
                    <div className="col-span-3">
                        <span className={Classes.TEXT_MUTED}>Position</span>
                        <p>
                            <button onClick={props.handleGoTo} className="hover:underline">
                                {latitude?.toFixed(5)}, {longitude?.toFixed(5)}
                            </button>
                        </p>
                    </div>
                    <div className="col-span-3">
                        <span className={Classes.TEXT_MUTED}>Timestamp</span>
                        <p>
                            {properties?.ts
                                ? DateTime.fromISO(properties.ts).toLocaleString(DateTime.DATETIME_FULL)
                                : "-"}
                        </p>
                    </div>
                    <div className="col-span-3">
                        <span className={Classes.TEXT_MUTED}>Confidence</span>
                        <p>{properties?.confidence ? `${properties.confidence * 10}%` : "-"}</p>
                    </div>
                </SectionCard>
            </StyleInjector>
        </QueryClientProvider>
    );
};
