import type { LayerProps } from "react-map-gl/maplibre";

export const selectionInner: LayerProps = {
  id: "selection-inner",
  type: "circle",
  paint: {
    "circle-blur": 0.0,
    "circle-radius": 12,
    "circle-opacity": 0.0,
    "circle-stroke-color": "#92e829",
    "circle-stroke-opacity": 0.7,
    "circle-stroke-width": 1.5,
  },
};

export const selectionOuter: LayerProps = {
  id: "selection-outer",
  type: "circle",
  paint: {
    "circle-blur": 0.0,
    "circle-opacity": 0.0,
    "circle-stroke-color": "#8fe100",
    "circle-stroke-opacity": 0.2,
    "circle-stroke-width": 6,
  },
};
