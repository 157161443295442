import { Callout, Classes, SectionCard } from "@blueprintjs/core";
import type { Feature, Point } from "geojson";
import { DateTime } from "luxon";

type PanelProps = {
  feature: Feature;
};

export const DronePanelHeader = () => {
  return <Callout title="Drone" />;
};

type DataPanelProps = PanelProps & {
  handleGoTo: () => void;
};

export const DroneDataPanel = ({ feature, handleGoTo }: DataPanelProps) => {
  const { properties } = feature;
  const [longitude, latitude] = (feature.geometry as Point).coordinates;

  return (
    <SectionCard className="grid grid-cols-3 gap-y-2 text-xs">
      <div className="col-span-3">
        <span className={Classes.TEXT_MUTED}>Position</span>
        <p>
          <button onClick={handleGoTo} className="hover:underline">
            {latitude?.toFixed(5)}, {longitude?.toFixed(5)}
          </button>
        </p>
      </div>
      <div>
        <span className={Classes.TEXT_MUTED}>Speed</span>
        <p>{properties?.speed ? `${properties.speed} m/s` : "-"}</p>
      </div>
      <div>
        <span className={Classes.TEXT_MUTED}>Heading</span>
        <p>{properties?.heading ? `${properties.heading}°` : "-"}</p>
      </div>
      <div>
        <span className={Classes.TEXT_MUTED}>Altitude</span>
        <p>{properties?.altitude ? `${properties.altitude} m` : "-"}</p>
      </div>
      <div className="col-span-3">
        <span className={Classes.TEXT_MUTED}>Last Report</span>
        <p>{DateTime.fromISO(properties?.last_report).toLocaleString(DateTime.DATETIME_FULL)}</p>
      </div>
    </SectionCard>
  );
};
