import { DateTime } from "luxon";

export type MissionId = string;
export type Status = "Ordered" | "Rejected" | "In Progress" | "Complete";
export type Mission = {
  id: MissionId;
  name: string;
  status: Status;
  progress?: number;
  ordered: string;
  started?: string;
  finished?: string;
};

export type MissionQuery = {
  subject?: string;
};

interface IMissionService {
  search: (query?: MissionQuery) => Promise<Mission[]>;
}

const MissionService: IMissionService = {
  search: async () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const rand = Math.random();
        if (rand < 0.1) reject("Simulated Error");
        else if (rand > 0.1 && rand < 0.2) resolve([]);
        else
          resolve([
            {
              id: "mission-00001",
              name: "Mission A",
              status: "Ordered",
              ordered: DateTime.now()
                .minus({ minute: Math.random() * 30 + 29 })
                .toISO()!,
            },
            {
              id: "mission-00002",
              name: "Mission B",
              status: "In Progress",
              progress: 0.2,
              ordered: DateTime.now()
                .minus({ minute: Math.random() * 30 + 29 })
                .toISO()!,
              started: DateTime.now()
                .minus({ minute: Math.random() * 15 })
                .toISO()!,
            },
            {
              id: "mission-00003",
              name: "Mission C",
              status: "Rejected",
              ordered: DateTime.now()
                .minus({ minute: Math.random() * 30 + 29 })
                .toISO()!,
              finished: DateTime.now()
                .minus({ minute: Math.random() * 29 })
                .toISO()!,
            },
            {
              id: "mission-00004",
              name: "Mission D",
              status: "Complete",
              ordered: DateTime.now()
                .minus({ minute: Math.random() * 30 + 29 })
                .toISO()!,
              started: DateTime.now()
                .minus({ minute: Math.random() * 15 + 14 })
                .toISO()!,
              finished: DateTime.now()
                .minus({ minute: Math.random() * 15 })
                .toISO()!,
              progress: 1.0,
            },
          ]);
      }, 1000);
    });
  },
};

export default MissionService;
