import { DateTime } from "luxon";
import type { Feature, Point } from "geojson";

import { Callout, Classes, SectionCard } from "@blueprintjs/core";
import { StyleInjector } from "../loading";
import AircraftTag from "../AircraftTag";
import { AircraftCategoryToString } from "../../util/adsb.ts";

type PanelProps = {
  feature: Feature;
  isLoading: boolean;
};

export const AdsbPanelHeader = (props: PanelProps) => {
  const { properties } = props.feature;
  return (
    <StyleInjector isLoading={props.isLoading}>
      <Callout>
        <h5 className={Classes.HEADING}>{properties?.callsign || "UNKNOWN CALLSIGN"}</h5>
        <span className="float-right">
          {properties?.squawk && <AircraftTag squawk={properties.squawk} />}
        </span>
        <p>{AircraftCategoryToString(properties?.category)}</p>
      </Callout>
    </StyleInjector>
  );
};

type DataPanelProps = PanelProps & {
  handleGoTo: () => void;
};

export const AdsbDataPanel = (props: DataPanelProps) => {
  const { properties } = props.feature;
  const [longitude, latitude] = (props.feature.geometry as Point).coordinates;

  return (
    <StyleInjector isLoading={props.isLoading}>
      <SectionCard className="grid grid-cols-3 gap-y-2 text-xs">
        <div className="col-span-3">
          <span className={Classes.TEXT_MUTED}>Position</span>
          <p>
            <button onClick={props.handleGoTo} className="hover:underline">
              {latitude?.toFixed(5)}, {longitude?.toFixed(5)}
            </button>
          </p>
        </div>
        <div>
          <span className={Classes.TEXT_MUTED}>Speed</span>
          <p>{properties?.velocity ? `${properties.velocity} m/s` : "-"}</p>
        </div>
        <div>
          <span className={Classes.TEXT_MUTED}>Heading</span>
          <p>{properties?.true_track ? `${properties.true_track}°` : "-"}</p>
        </div>
        <div>
          <span className={Classes.TEXT_MUTED}>Altitude</span>
          <p>{properties?.baro_altitude ? `${properties.baro_altitude} m` : "-"}</p>
        </div>
        <div>
          <span className={Classes.TEXT_MUTED}>Vertical Speed</span>
          <p>{properties?.vertical_rate ? `${properties.vertical_rate} m/s` : "-"}</p>
        </div>
        <div className="col-span-2">
          <span className={Classes.TEXT_MUTED}>Squawk</span>
          <p>{properties?.squawk ? `${properties.squawk}` : "-"}</p>
        </div>
        <div>
          <span className={Classes.TEXT_MUTED}>ICAO24</span>
          <p>{properties?.icao24 ? `${properties.icao24}` : "-"}</p>
        </div>
        <div className="col-span-2">
          <span className={Classes.TEXT_MUTED}>Country of Origin</span>
          <p>{properties?.origin_country ? `${properties.origin_country}` : "-"}</p>
        </div>
        <div className="col-span-2">
          <span className={Classes.TEXT_MUTED}>Last Report</span>
          <p>
            {properties?.last_contact &&
              DateTime.fromSeconds(properties.last_contact).toLocaleString(DateTime.DATETIME_FULL)}
          </p>
        </div>
      </SectionCard>
    </StyleInjector>
  );
};
