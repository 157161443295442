import { useState } from "react";
import { Classes, Collapse, Divider, Icon, SectionCard } from "@blueprintjs/core";
import type { IconName } from "@blueprintjs/core";

type Props = React.PropsWithChildren & {
  title: string;
  icon?: IconName;
  padded?: boolean;
  defaultIsOpen?: boolean;
};

const CollapsableSectionCard = (props: Props) => {
  const [isOpen, setIsOpen] = useState(props.defaultIsOpen ?? false);

  return (
    <SectionCard padded={props.padded}>
      <div
        className="flex items-center justify-between px-3 py-2 hover:cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex items-center gap-2">
          {props.icon && <Icon icon={props.icon} className={Classes.TEXT_MUTED} />}
          <h5 className="font-medium">{props.title}</h5>
        </div>
        <Icon icon={isOpen ? "chevron-up" : "chevron-down"} className={Classes.TEXT_MUTED} />
      </div>
      <Collapse isOpen={isOpen}>
        <Divider className="m-0" />
        {props.children}
      </Collapse>
    </SectionCard>
  );
};

export default CollapsableSectionCard;
