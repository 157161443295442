import type { LayerProps } from "react-map-gl/maplibre";

export const ais: LayerProps = {
  id: "ais",
  type: "symbol",
  filter: ["==", ["get", "sensor"], "ais"],
  minzoom: 8,
  layout: {
    "icon-optional": false,
    "icon-allow-overlap": true,
    "icon-image":["image", [ "concat",
        [ "step",
          ["to-number", ["get", "type"]], // ais type (none or null evaluate to 0)
          "unspecified",         // | 0-9   | Invalid                          | Invalid or No Data  |
          10, "unspecified",     // | 10-19 | Reserved                         | Unspecified         |
          20, "wing-in-ground",  // | 20-28 | Wing in Ground                   | Wing in Ground      |
          29, "sar-aircraft",    // | 29    | SAR Aircraft                     | Search and Rescue   |
          30, "fishing",         // | 30    | Fishing                          | Fishing             |
          31, "tug",             // | 31-32 | Tug                              | Tug                 |
          33, "special",         // | 33    | Dredger                          | Special Craft       |
          34, "special",         // | 34    | Dive Vessel                      | Special Craft       |
          35, "special",         // | 35    | Military Ops                     | Special Craft       |
          36, "special",         // | 36    | Sailing Vessel                   | Special Craft       |
          37, "pleasure",        // | 37    | Pleasure Craft                   | Pleasure Craft      |
          38, "unspecified",     // | 38-39 | Reserved                         | Unspecified         |
          40, "high-speed",      // | 40-49 | High-Speed Craft                 | High-Speed Craft    |
          50, "special",         // | 50    | Pilot Vessel                     | Special Craft       |
          51, "sar",             // | 51    | SAR                              | Search and Rescue   |
          52, "tug",             // | 52    | Tug                              | Tug                 |
          53, "special",         // | 53    | Port Tender                      | Special Craft       |
          54, "special",         // | 54    | Anti-Pollution                   | Special Craft       |
          55, "special",         // | 55    | Law Enforcement                  | Special Craft       |
          56, "special",         // | 56-57 | Local Vessel                     | Special Craft       |
          58, "special",         // | 58    | Medical Transport                | Special Craft       |
          59, "special",         // | 59    | Special Craft                    | Special Craft       |
          60, "passenger",       // | 60-69 | Passenger                        | Passenger           |
          70, "cargo",           // | 70    | Cargo                            | Cargo               |
          71, "cargo",           // | 71    | Cargo - Hazard X (Major)         | Cargo               |
          72, "cargo",           // | 72    | Cargo - Hazard Y                 | Cargo               |
          73, "cargo",           // | 73    | Cargo - Hazard Z (Minor)         | Cargo               |
          74, "cargo",           // | 74    | Cargo - Hazard OS (Recognizable) | Cargo               |
          75, "cargo",           // | 75-79 | Cargo                            | Cargo               |
          80, "tanker",          // | 80    | Tanker                           | Tanker              |
          81, "tanker",          // | 81    | Tanker - Hazard A (Major)	       | Tanker              |
          82, "tanker",          // | 82    | Tanker - Hazard B                | Tanker              |
          83, "tanker",          // | 83    | Tanker - Hazard C (Minor)        | Tanker              |
          84, "tanker",          // | 84    | Tanker - Hazard D (Recognizable) | Tanker              |
          85, "tanker",          // | 85-89 | Tanker                           | Tanker              |
          90, "unspecified",     // | 90-99 | Other                            | Other               |
        ],
        "-",
        [
          "case",
            [">", ["to-number", ["get", "sog"]], 0], "a",
            ["<=", ["to-number", ["get", "sog"]], 0], "b",
            "b"
        ]
    ]],
    "icon-pitch-alignment": "map",
    "icon-rotation-alignment": "map",
    "icon-rotate": ["to-number", ["get", "cog"]]
  },
};

export const vessel_simple: LayerProps = {
  id: "ais-simple",
  type: "circle",
  filter: ["==", ["get", "sensor"], "ais"],
  minzoom: 4,
  maxzoom: 8,
  paint: {
    "circle-radius": ["interpolate", ["linear"], ["zoom"], 5, 1.5, 12, 3],
    "circle-color": "#9d4125",
    "circle-stroke-width": ["interpolate", ["linear"], ["zoom"], 5, 0.0, 14, 10],
    "circle-stroke-color": "#f590a6",
    "circle-stroke-opacity": ["case", ["boolean", ["feature-state", "hover"], false], 0.15, 0.05],
  },
};

export const vessel_flagged: LayerProps = {
  id: "ais-flagged",
  type: "circle",
  filter: ["all", ["==", ["get", "sensor"], "ais"], ["boolean", ["get", "flagged"], false]],
  minzoom: 4,
  paint: {
    "circle-radius": 12,
    "circle-color": "#ff6347",
    "circle-opacity": 0.5,
    "circle-stroke-color": "#ff6347",
    "circle-stroke-opacity": 0.2,
    "circle-stroke-width": 4,
  },
};

export const hull: LayerProps = {
  id: "ais-hull",
  type: "symbol",
  minzoom: 12,
  layout: {
    "icon-allow-overlap": true,
    "text-field": "{name}",
    "text-size": 12,
    "text-offset": [3.5, -0.6],
    "text-anchor": "bottom",
  },
  paint: {
    "text-color": "#FFF",
  },
};
