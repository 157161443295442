import { Classes, Icon, SectionCard } from "@blueprintjs/core";
import type { Feature } from "../stores/model";
import { Radar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  PointElement,
  RadialLinearScale,
} from "chart.js";
import { NavStatusToString, VesselTypeToString } from "../util/ais";
import { memo, useMemo } from "react";

type Props = {
  feature: Feature;
};

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Legend);

// TODO: remove => this is some fakeness for demo purposes
const getRandomData = (vesselType: string, navstat?: string) => {
  const data: Record<string, number> = {
    Fishing: vesselType === "Fishing Vessel" ? 9 : 3,
    Anchored: navstat === "At Anchor" ? 7 : 3,
    Drifting: 3,
    Transiting: navstat === "Sailing" || navstat === "Under Way" ? 7 : 3,
    Moored: navstat === "Moored" ? 7 : 3,
    Loitering: 3,
  };

  const noise = Array.from({ length: 6 }, () => Math.random() * 3);

  return Object.keys(data).map((key, i) => {
    return Math.max(0, i % 2 === 0 ? data[key]! - noise[i]! : data[key]! + noise[i]!);
  });
};

const BehaviorPanel = memo(({ feature }: Props) => {
  const { properties } = feature;
  const navstat = NavStatusToString(properties?.navstat);
  const vesselType = VesselTypeToString(properties?.type);
  // New data should be generated as different vessels are inspected
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const data = useMemo(() => getRandomData(vesselType, navstat), [feature.id, navstat, vesselType]);

  if (properties?.sensor !== "ais") return null;

  const chartData = {
    labels: ["Fishing", "Anchored", "Drifting", "Transiting", "Moored", "Loitering"],
    datasets: [
      {
        data: data,
        backgroundColor: "rgba(255, 99, 132, 0.3)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
    ],
  };

  return (
    <SectionCard padded={false}>
      <div className="h-56 px-3 py-2">
        <div className="flex items-center gap-2">
          <Icon className={Classes.TEXT_MUTED} icon="series-search" />
          <h5 className="font-medium">Behavior Classification</h5>
        </div>
        <Radar
          data={chartData}
          options={{
            plugins: { legend: { display: false } },
            elements: { point: { pointStyle: "triangle" } },
            scales: {
              r: {
                grid: { color: "rgba(171, 179, 191, 0.3)" },
                ticks: { display: false, maxTicksLimit: 7 },
                pointLabels: {
                  color: "rgb(246, 247, 249)",
                  padding: 0,
                  font: { size: 12 },
                },
                min: 0,
                max: 10,
              },
            },
          }}
          className="mx-auto"
        />
      </div>
    </SectionCard>
  );
});

export default BehaviorPanel;
