import type { LayerProps } from "react-map-gl/maplibre";

export const au_eez_limit: LayerProps = {
  id: "eezau",
  minzoom: 1,
  type: "line",
  layout: {
    "line-join": "round",
    "line-cap": "round",
  },
  paint: {
    "line-color": "#467317",
    "line-width": 1.5,
  },
};

export const nz_eez_limit: LayerProps = {
  id: "eeznz",
  minzoom: 1,
  type: "line",
  layout: {
    "line-join": "round",
    "line-cap": "round",
  },
  paint: {
    "line-color": "#732F51",
    "line-width": 1.5,
  },
};

export const pac_eez_limit: LayerProps = {
  id: "eezpac",
  minzoom: 1,
  type: "line",
  layout: {
    "line-join": "round",
    "line-cap": "round",
  },
  paint: {
    "line-color": "#623E73",
    "line-width": 1.5,
  },
};
