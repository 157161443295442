import type { Feature } from "geojson";

import useTaskingStore from "../../stores/tasking";
import useApplicationStore from "../../stores/application.ts";
import type { ObjectiveType } from "../../stores/model.ts";

import {
  Button,
  Card,
  DialogBody,
  DialogStep,
  MultistepDialog,
  NonIdealState,
  Tag,
} from "@blueprintjs/core";

// TODO: remove
const objectives: ObjectiveType[] = ["ACQUIRE", "MOVETO", "FOLLOW"];
////
const ObjectiveSelectStep = () => {
  const { objective, setObjective, setCOAs } = useTaskingStore();

  // TODO: properly
  const handleSetObjective = (type: ObjectiveType) => {
    setObjective({ id: "123", type });
    setCOAs([
      { id: "coa5", priority: "HIGH", type: "coa_type_4" },
      { id: "coa2", priority: "MODERATE", type: "coa_type_2" },
      { id: "coa1", priority: "MODERATE", type: "coa_type_1" },
      { id: "coa3", priority: "LOW", type: "coa_type_3" },
      { id: "coa4", priority: "LOW", type: "coa_type_3" },
    ]);
  };

  return (
    <DialogBody className="max-h-[32rem] overflow-auto">
      {objectives.map((objType, idx) => {
        const isActive = objective?.type === objType;
        return (
          <Card key={idx} className="flex items-center justify-between">
            <div>
              <h2 className="font-medium">{objType.toLowerCase()}</h2>
              <p className="text-xs">This objective has some description...</p>
            </div>
            <Button
              intent={isActive ? "success" : "none"}
              disabled={isActive}
              small
              onClick={() => handleSetObjective(objType)}
            >
              {isActive ? "Selected" : "Select"}
            </Button>
          </Card>
        );
      })}
    </DialogBody>
  );
};

const COASelectStep = ({ feature }: { feature: Feature | undefined }) => {
  const { COAs, activeTasks, addActiveTask } = useTaskingStore();

  if (COAs.length === 0 || !feature) {
    return (
      <Card>
        <NonIdealState title="Something went wrong" icon="error" />
      </Card>
    );
  }

  // TODO: properly
  const handleSetTask = (id: string) =>
    addActiveTask(feature.id as string, { id, status: "PENDING" });

  return (
    <DialogBody className="max-h-[32rem] overflow-auto">
      {COAs.map((coa) => {
        const isActive = activeTasks[feature.id as string]?.id === coa.id;
        return (
          <Card key={coa.id}>
            <div className="mb-2 flex items-center justify-between">
              <h2 className="font-medium">{coa.type}</h2>
              <div className="flex flex-col items-end">
                <Tag
                  intent={
                    coa.priority === "HIGH"
                      ? "danger"
                      : coa.priority === "MODERATE"
                      ? "warning"
                      : "none"
                  }
                >
                  {coa.priority}
                </Tag>
                <span className="text-xs text-white/50">priority</span>
              </div>
            </div>
            <Button
              intent={isActive ? "success" : "none"}
              disabled={isActive}
              small
              onClick={() => handleSetTask(coa.id)}
            >
              {isActive ? "ACTIVE" : "Order"}
            </Button>
          </Card>
        );
      })}
    </DialogBody>
  );
};

type DialogProps = {
  isOpen: boolean;
  onClose?: () => void;
};

const TaskingDialog = (props: DialogProps) => {
  const { hooked } = useApplicationStore();
  const { features } = useApplicationStore();
  const { objective } = useTaskingStore();

  return (
    <MultistepDialog
      title="Mission Tasking"
      icon="playbook"
      nextButtonProps={{ disabled: !objective }}
      isOpen={props.isOpen}
      onClose={props?.onClose}
    >
      <DialogStep id={0} title="Objective Select" panel={<ObjectiveSelectStep />} />
      <DialogStep id={1} title="CoA Select" panel={<COASelectStep feature={features[hooked!]} />} />
    </MultistepDialog>
  );
};

export default TaskingDialog;
