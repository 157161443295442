import { fromUrl } from "geotiff";
import { Classes, NonIdealState, NonIdealStateIconSize } from "@blueprintjs/core";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import Magnifier from "react-magnifier";

export interface ChipProps extends React.RefAttributes<HTMLCanvasElement> {
  id: string;
  width: number;
  height: number;
}

const Chip = (props: ChipProps) => {
  const sar = useQuery({
    queryKey: [props.id],
    queryFn: async () => {
      const url = `${import.meta.env.VITE_API_BASE_URL ?? ""}/api/v1/detection/${props.id}/image`;
      const geotiff = await fromUrl(url, { allowFullFile: true });
      const image = await geotiff.getImage();
      const rgb = (await image.readRGB()) as Uint8Array;
      return {
        width: image.getWidth(),
        height: image.getHeight(),
        rgb: rgb,
      };
    },
  });

  if (sar.isLoading)
    return (
      <div className={Classes.SKELETON} style={{ width: props.width, height: props.height }} />
    );

  if (sar.data) {
    const { rgb, width, height } = sar.data;
    // Create a canvas where we will render the originally sized image.
    const srcCanvas = document.createElement("canvas");
    const srcContext = srcCanvas.getContext("2d", { willReadFrequently: true })!;

    // Set the source canvas to the same width and height of the original image.
    srcCanvas.width = width;
    srcCanvas.height = height;

    // Convert the GeoTiff RGB values to ImageData RGBA values
    const imageData = srcContext.getImageData(0, 0, width, height);
    const data = imageData.data;
    for (let i = 0; i < height; i++) {
      for (let j = 0; j < width; j++) {
        const srcIdx = 3 * i * width + 3 * j;
        const idx = 4 * i * width + 4 * j;
        data[idx] = rgb[srcIdx]!;
        data[idx + 1] = rgb[srcIdx + 1]!;
        data[idx + 2] = rgb[srcIdx + 2]!;
        data[idx + 3] = 255; // fully opaque
      }
    }
    srcContext.putImageData(imageData, 0, 0);

    // Get the destination canvas reference (where we will draw a scaled version of the original image).
    const dstCanvas = document.createElement("canvas");
    const dstCtx = dstCanvas.getContext("2d")!;

    // Set destination canvas to the desired size of rendered image.
    dstCanvas.width = props.width;
    dstCanvas.height = props.height;

    // Draw the image to the destination canvas with appropriate scaling.
    dstCtx.drawImage(srcCanvas, 0, 0, width, height, 0, 0, dstCanvas.width, dstCanvas.height);

    const image = dstCanvas.toDataURL("image/jpg", 1.0);

    return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Magnifier src={image} mgShowOverflow={false} className="h-full w-full rounded"/>
    )
  }

  return (
    <NonIdealState
      icon="error"
      iconSize={NonIdealStateIconSize.STANDARD}
      title="Error"
      description="Failed to fetch SAR image."
    />
  );
};

export default Chip;
