import { useCallback } from "react";

import useApplicationStore from "../stores/application.ts";
import type { Layer } from "../stores/model.ts";

import { Alignment, Button, Checkbox, Classes, Navbar, Popover, Tooltip } from "@blueprintjs/core";

const Header = () => {
  const { layers, toggleLayer, setIsOmnibarOpen } = useApplicationStore();

  const isVisible = useCallback((id: Layer) => layers.includes(id), [layers]);

  return (
    <div className="flex w-full items-center justify-between bg-secondary px-4 shadow">
      <div className="flex flex-row-reverse gap-2">
        <Tooltip placement="bottom" content={"Shift + Space"}>
          <Button icon="search" minimal onClick={() => setIsOmnibarOpen(true)} />
        </Tooltip>
        <Popover
          popoverClassName={Classes.POPOVER_CONTENT_SIZING}
          placement="bottom-end"
          content={
            <div className="flex flex-col gap-y-2">
              <h4 className="font-medium">Toggle Layers</h4>
              <div>
                <Checkbox
                  label="Submarine Cables"
                  checked={isVisible("cable")}
                  onClick={() => toggleLayer("cable")}
                />
                <Checkbox
                  label="Economic Exclusion Zones"
                  checked={isVisible("eez")}
                  onClick={() => toggleLayer("eez")}
                />
              </div>
            </div>
          }
          renderTarget={({ isOpen, ...targetProps }) => (
            <Button icon="filter" minimal active={isOpen} {...targetProps} />
          )}
        />
      </div>
      <nav>
        <Navbar.Group align={Alignment.RIGHT} className="gap-2">
          <Button icon="user" minimal />
          <Button icon="notifications" minimal />
          <Button icon="cog" minimal />
        </Navbar.Group>
      </nav>
    </div>
  );
};

export default Header;
