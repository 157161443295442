import { Feature, FeatureCollection } from "geojson";
import methods from "./base.ts";
import { cyrb53 } from "../util/hash.ts";

export type FeatureType = "ais" | "adsb" | "sar" | "viirs-af" | "drone";

export type Bounds = number[];

export type FeatureQuery = {
  type: FeatureType;
  bounds: Bounds;
  fields: string[] | null;
  signal?: AbortSignal;
};

export async function search(query: FeatureQuery): Promise<FeatureCollection> {
  const [sw_lng, sw_lat, ne_lng, ne_lat] = query.bounds;
  const collection = await methods.get<FeatureCollection>({
    basePath: import.meta.env.VITE_V2_API_BASE_URL,
    path: `/v1/tracks/${query.type}`,
    query: { sw_lat, sw_lng, ne_lat, ne_lng, fields: query.fields },
    signal: query.signal,
  });

  collection.features = collection?.features.map((feature) => {
    // We recompute feature IDs to a cyrb53 equivalent hash value. Because the downstream map library we use
    // has issues with string feature IDs (see https://github.com/mapbox/mapbox-gl-js/issues/2716). We have
    // chosen to re-write the IDs as early as possible to ensure no discrepancy between values returned by this
    // service API and the ID values contained in data stores.
    feature.properties = { id: feature.id, ...feature.properties, sensor: query.type as string };
    feature.id = cyrb53(`${feature.id}`);
    return feature;
  });

  return collection;
}

export async function get(type: string, id: string): Promise<Feature> {
  const feature = await methods.get<Feature>({
    basePath: import.meta.env.VITE_V2_API_BASE_URL,
    path: `/v1/tracks/${type}/${id}`,
  });
  feature.properties = { id: feature.id, ...feature.properties, sensor: type };
  feature.id = cyrb53(`${feature.id}`);
  return feature;
}
