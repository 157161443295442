import { Feature } from "./model.ts";
import { immer } from "zustand/middleware/immer";

export interface FeatureSlice {
  features: Record<string | number, Feature>;
  insert: (features: Feature[]) => void;
  remove: (ids: Array<string | number>) => void;
}

export const createFeatureSlice = immer<FeatureSlice>((set) => ({
  features: {},
  insert: (features) => {
    set((state) => {
      features.reduce((obj, feature) => {
        obj[feature.id!] = feature;
        return obj;
      }, state.features);
    });
  },
  remove: (ids) => {
    set((state) => {
      ids.reduce((obj, id) => {
        delete obj[id];
        return obj;
      }, state.features);
    });
  },
}));

export default createFeatureSlice;
