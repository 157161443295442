import { Tag, Tooltip } from "@blueprintjs/core";

type Props = {
  squawk: number;
};

const AircraftTag = ({ squawk }: Props) => {
  switch (squawk) {
    case 7500:
      return (
        <Tooltip content={`Squawk ${squawk}`} compact>
          <Tag intent="danger">Hijacking</Tag>
        </Tooltip>
      );
    case 7600:
      return (
        <Tooltip content={`Squawk ${squawk}`} compact>
          <Tag intent="danger">Radio Failure</Tag>
        </Tooltip>
      );
    case 7700:
      return (
        <Tooltip content={`Squawk ${squawk}`} compact>
          <Tag intent="danger">Emergency</Tag>
        </Tooltip>
      );
    default:
      return null;
  }
};

export default AircraftTag;
