import { create } from "zustand";
import { produce } from "immer";
import { COA, Objective, Task } from "./model.ts";

type TaskingStore = {
  objective: Objective | undefined;
  setObjective: (o: Objective) => void;
  COAs: COA[];
  setCOAs: (coas: COA[]) => void;
  activeTasks: Record<string, Task>;
  addActiveTask: (entityId: string, task: Task) => void;
  popActiveTask: (entityId: string) => void;
};

const useTaskingStore = create<TaskingStore>()((set) => ({
  objective: undefined,
  setObjective: (o) => set(() => ({ objective: o })),
  COAs: [],
  setCOAs: (coas) => set(() => ({ COAs: coas })),
  activeTasks: {},
  addActiveTask: (entityId, task) =>
    set(
      produce((state) => {
        state.activeTasks[entityId] = task;
      }),
    ),
  popActiveTask: (entityId) =>
    set(
      produce((state) => {
        delete state.activeTasks[entityId];
      }),
    ),
}));

export default useTaskingStore;
