import type { PropsWithChildren } from "react";
import { Elevation, Section } from "@blueprintjs/core";

const FeatureSelection = ({ children }: PropsWithChildren) => {
  return (
    <Section
      icon={"target"}
      elevation={Elevation.ONE}
      className="absolute right-2 top-2 z-10 max-w-[300px]"
      compact
    >
      {children}
    </Section>
  );
};

export default FeatureSelection;
