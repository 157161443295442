export const AircraftCategoryToString = (category: number) => {
  switch (category) {
    case 2:
      return "Light Aircraft";
    case 3:
      return "Small Aircraft";
    case 4:
      return "Large Aircraft";
    case 5:
      return "High Vortex Large Aircraft";
    case 6:
      return "Heavy Aircraft";
    case 7:
      return "High Performance Aircraft";
    case 8:
      return "Rotorcraft";
    case 9:
      return "Glider / Sailplane";
    case 10:
      return "Lighter-than-air";
    case 11:
      return "Parachutist / Skydiver";
    case 12:
      return "Ultralight";
    case 13:
      return "Reserved";
    case 14:
      return "Unmanned Aerial Vehicle";
    case 15:
      return "Space / Trans-atmospheric Vehicle";
    case 16:
      return "Emergency Vehicle";
    case 17:
      return "Service Vehicle";
    case 18:
      return "Point Obstacle";
    case 19:
      return "Cluster Obstacle";
    case 20:
      return "Line Obstacle";
    default:
      return "Aircraft (Other / Unknown)";
  }
};
