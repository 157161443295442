import { useMap } from "react-map-gl";
import distance from "@turf/distance";
import bearing from "@turf/bearing";
import { DateTime } from "luxon";
import type { Feature, GeoJsonProperties, Point } from "geojson";

import { Classes, ContextMenuPopover, Divider, Menu, MenuItem } from "@blueprintjs/core";

import useApplicationStore from "../stores/application";

const ContextMenu = () => {
  const { features, hooked, ballTabbed, setActivity } = useApplicationStore();

  const current = hooked ? features[hooked] : null;
  const other = ballTabbed ? features[ballTabbed] : null;

  return (
    <Menu>
      {current && other && current.id !== other.id && (
        <>
          <div className="m-1.5">
            <h5 className={Classes.HEADING}>
              {other.properties?.sensor === "ais"
                ? other.properties?.name || "UNKNOWN ENTITY"
                : other.properties?.sensor === "adsb"
                ? other.properties?.callsign
                : "SAR Detection"}
            </h5>
            <div className="flex justify-between">
              <span className={Classes.TEXT_MUTED}>Distance</span>
              <p>
                {distance(
                  current as Feature<Point, GeoJsonProperties>,
                  other as Feature<Point, GeoJsonProperties>,
                  "nauticalmiles",
                ).toFixed(2)}{" "}
                nm
              </p>
            </div>
            <div className="flex justify-between">
              <span className={Classes.TEXT_MUTED}>Bearing</span>
              <p>
                {bearing(
                  current as Feature<Point, GeoJsonProperties>,
                  other as Feature<Point, GeoJsonProperties>,
                ).toFixed(2)}
                &deg;
              </p>
            </div>
            <div className="flex justify-between">
              <span className={Classes.TEXT_MUTED}>Last Contact</span>
              <p
                title={DateTime.fromISO(
                  other.properties?.timestamp || other.properties?.last_contact,
                ).toLocaleString(DateTime.DATETIME_MED)}
              >
                {DateTime.fromISO(
                  other.properties?.timestamp || other.properties?.last_contact,
                ).toRelative({ style: "short" })}
              </p>
            </div>
          </div>
          <Divider />
        </>
      )}
      <MenuItem text="Manage Mission" icon="playbook" onClick={() => setActivity("mission")} />
    </Menu>
  );
};

const FeatureContextMenu = () => {
  const { features, ballTabbed, setBallTabbed } = useApplicationStore();
  const map = useMap();

  if (!ballTabbed) return null;
  const coordinates = (features[ballTabbed]?.geometry as Point).coordinates;
  const point = map.current?.project({ lon: coordinates[0]!, lat: coordinates[1]! });
  if (!point) return null;

  return (
    <ContextMenuPopover
      isOpen={true}
      targetOffset={{ left: point.x + 4, top: point.y + 60 }}
      onClose={() => setBallTabbed(undefined)}
      content={<ContextMenu />}
    />
  );
};

export default FeatureContextMenu;
