const mids: Record<string, [string, string, string, string]> = {
  "201": ["AL", "ALB", "", "Albania"],
  "202": ["AD", "AND", "", "Andorra"],
  "203": ["AT", "AUT", "", "Austria"],
  "204": ["PT", "PRT", "PT-20", "Azores"],
  "205": ["BE", "BEL", "", "Belgium"],
  "206": ["BY", "BLR", "", "Belarus"],
  "207": ["BG", "BGR", "", "Bulgaria"],
  "208": ["VA", "VAT", "", "Vatican City State"],
  "209": ["CY", "CYP", "", "Cyprus"],
  "210": ["CY", "CYP", "", "Cyprus"],
  "211": ["DE", "DEU", "", "Germany"],
  "212": ["CY", "CYP", "", "Cyprus"],
  "213": ["GE", "GEO", "", "Georgia"],
  "214": ["MD", "MDA", "", "Moldova"],
  "215": ["MT", "MLT", "", "Malta"],
  "216": ["AM", "ARM", "", "Armenia"],
  "218": ["DE", "DEU", "", "Germany"],
  "219": ["DK", "DNK", "", "Denmark"],
  "220": ["DK", "DNK", "", "Denmark"],
  "224": ["ES", "ESP", "", "Spain"],
  "225": ["ES", "ESP", "", "Spain"],
  "226": ["FR", "FRA", "", "France"],
  "227": ["FR", "FRA", "", "France"],
  "228": ["FR", "FRA", "", "France"],
  "229": ["MT", "MLT", "", "Malta"],
  "230": ["FI", "FIN", "", "Finland"],
  "231": ["FO", "FRO", "", "Faroe Islands"],
  "232": ["GB", "GBR", "", "United Kingdom"],
  "233": ["GB", "GBR", "", "United Kingdom"],
  "234": ["GB", "GBR", "", "United Kingdom"],
  "235": ["GB", "GBR", "", "United Kingdom"],
  "236": ["GI", "GIB", "", "Gibraltar"],
  "237": ["GR", "GRC", "", "Greece"],
  "238": ["HR", "HRV", "", "Croatia"],
  "239": ["GR", "GRC", "", "Greece"],
  "240": ["GR", "GRC", "", "Greece"],
  "241": ["GR", "GRC", "", "Greece"],
  "242": ["MA", "MAR", "", "Morocco"],
  "243": ["HU", "HUN", "", "Hungary"],
  "244": ["NL", "NLD", "", "Netherlands"],
  "245": ["NL", "NLD", "", "Netherlands"],
  "246": ["NL", "NLD", "", "Netherlands"],
  "247": ["IT", "ITA", "", "Italy"],
  "248": ["MT", "MLT", "", "Malta"],
  "249": ["MT", "MLT", "", "Malta"],
  "250": ["IE", "IRL", "", "Ireland"],
  "251": ["IS", "ISL", "", "Iceland"],
  "252": ["LI", "LIE", "", "Liechtenstein"],
  "253": ["LU", "LUX", "", "Luxembourg"],
  "254": ["MC", "MCO", "", "Monaco"],
  "255": ["PT", "PRT", "PT-30", "Madeira"],
  "256": ["MT", "MLT", "", "Malta"],
  "257": ["NO", "NOR", "", "Norway"],
  "258": ["NO", "NOR", "", "Norway"],
  "259": ["NO", "NOR", "", "Norway"],
  "261": ["PL", "POL", "", "Poland"],
  "262": ["ME", "MNE", "", "Montenegro"],
  "263": ["PT", "PRT", "", "Portugal"],
  "264": ["RO", "ROU", "", "Romania"],
  "265": ["SE", "SWE", "", "Sweden"],
  "266": ["SE", "SWE", "", "Sweden"],
  "267": ["SK", "SVK", "", "Slovak Republic"],
  "268": ["SM", "SMR", "", "San Marino"],
  "269": ["CH", "CHE", "", "Switzerland"],
  "270": ["CZ", "CZE", "", "Czech Republic"],
  "271": ["TR", "TUR", "", "Turkey"],
  "272": ["UA", "UKR", "", "Ukraine"],
  "273": ["RU", "RUS", "", "Russia"],
  "274": ["MK", "MKD", "", "Macedonia"],
  "275": ["LV", "LVA", "", "Latvia"],
  "276": ["EE", "EST", "", "Estonia"],
  "277": ["LT", "LTU", "", "Lithuania"],
  "278": ["SI", "SVN", "", "Slovenia"],
  "279": ["RS", "SRB", "", "Serbia"],
  "301": ["AI", "AIA", "", "Anguilla"],
  "303": ["US", "USA", "US-AK", "Alaska"],
  "304": ["AG", "ATG", "", "Antigua and Barbuda"],
  "305": ["AG", "ATG", "", "Antigua and Barbuda"],
  "306": ["CW", "CUW", "", "Antilles"],
  "307": ["AW", "ABW", "", "Aruba"],
  "308": ["BS", "BHS", "", "Bahamas"],
  "309": ["BS", "BHS", "", "Bahamas"],
  "310": ["BM", "BMU", "", "Bermuda"],
  "311": ["BS", "BMU", "", "Bahamas"],
  "312": ["BZ", "BLZ", "", "Belize"],
  "314": ["BB", "BRB", "", "Barbados"],
  "316": ["CA", "CAN", "", "Canada"],
  "319": ["KY", "CYM", "", "Cayman Islands"],
  "321": ["CR", "CRI", "", "Costa Rica"],
  "323": ["CU", "CUB", "", "Cuba"],
  "325": ["DM", "DMA", "", "Dominica"],
  "327": ["DO", "DOM", "", "Dominican Republic"],
  "329": ["GP", "GLP", "", "Guadeloupe"],
  "330": ["GD", "GRD", "", "Grenada"],
  "331": ["GL", "GRL", "", "Greenland"],
  "332": ["GT", "GTM", "", "Guatemala"],
  "335": ["HN", "HND", "", "Honduras"],
  "336": ["HT", "HTI", "", "Haiti"],
  "338": ["US", "USA", "", "United States of America"],
  "339": ["JM", "JAM", "", "Jamaica"],
  "341": ["KN", "KNA", "", "Saint Kitts and Nevis"],
  "343": ["LC", "LCA", "", "Saint Lucia"],
  "345": ["MX", "MEX", "", "Mexico"],
  "347": ["MQ", "MTQ", "", "Martinique"],
  "348": ["MS", "MSR", "", "Montserrat"],
  "350": ["NI", "NIC", "", "Nicaragua"],
  "351": ["PA", "PAN", "", "Panama"],
  "352": ["PA", "PAN", "", "Panama"],
  "353": ["PA", "PAN", "", "Panama"],
  "354": ["PA", "PAN", "", "Panama"],
  "355": ["PA", "PAN", "", "Panama"],
  "356": ["PA", "PAN", "", "Panama"],
  "357": ["PA", "PAN", "", "Panama"],
  "358": ["PR", "PRI", "", "Puerto Rico"],
  "359": ["SV", "SLV", "", "El Salvador"],
  "361": ["PM", "SPM", "", "Saint Pierre and Miquelon"],
  "362": ["TT", "TTO", "", "Trinidad and Tobago"],
  "364": ["TC", "TCA", "", "Turks and Caicos Islands"],
  "366": ["US", "USA", "", "United States of America"],
  "367": ["US", "USA", "", "United States of America"],
  "368": ["US", "USA", "", "United States of America"],
  "369": ["US", "USA", "", "United States of America"],
  "370": ["PA", "PAN", "", "Panama"],
  "371": ["PA", "PAN", "", "Panama"],
  "372": ["PA", "PAN", "", "Panama"],
  "373": ["PA", "PAN", "", "Panama"],
  "374": ["PA", "PAN", "", "Panama"],
  "375": ["VC", "VCT", "", "Saint Vincent and the Grenadines"],
  "376": ["VC", "VCT", "", "Saint Vincent and the Grenadines"],
  "377": ["VC", "VCT", "", "Saint Vincent and the Grenadines"],
  "378": ["VG", "VGB", "", "British Virgin Islands"],
  "379": ["VI", "VIR", "", "United States Virgin Islands"],
  "401": ["AF", "AFG", "", "Afghanistan"],
  "403": ["SA", "SAU", "", "Saudi Arabia"],
  "405": ["BD", "BGD", "", "Bangladesh"],
  "408": ["BH", "BHR", "", "Bahrain"],
  "410": ["BT", "BTN", "", "Bhutan"],
  "412": ["CN", "CHN", "", "China"],
  "413": ["CN", "CHN", "", "China"],
  "414": ["CN", "CHN", "", "China"],
  "416": ["TW", "TWN", "", "Taiwan"],
  "417": ["LK", "LKA", "", "Sri Lanka"],
  "419": ["IN", "IND", "", "India"],
  "422": ["IR", "IRN", "", "Iran"],
  "423": ["AZ", "AZE", "", "Azerbaijan"],
  "425": ["IQ", "IRQ", "", "Iraq"],
  "428": ["IL", "ISR", "", "Israel"],
  "431": ["JP", "JPN", "", "Japan"],
  "432": ["JP", "JPN", "", "Japan"],
  "434": ["TM", "TKM", "", "Turkmenistan"],
  "436": ["KZ", "KAZ", "", "Kazakhstan"],
  "437": ["UZ", "UZB", "", "Uzbekistan"],
  "438": ["JO", "JOR", "", "Jordan"],
  "440": ["KR", "KOR", "", "Korea"],
  "441": ["KR", "KOR", "", "Korea"],
  "443": ["PS", "PSE", "", "State of Palestine"],
  "445": ["KP", "PRK", "", "Democratic People's Republic of Korea"],
  "447": ["KW", "KWT", "", "Kuwait"],
  "450": ["LB", "LBN", "", "Lebanon"],
  "451": ["KG", "KGZ", "", "Kyrgyz Republic"],
  "453": ["MO", "MAC", "", "Macao"],
  "455": ["MV", "MDV", "", "Maldives"],
  "457": ["MN", "MNG", "", "Mongolia"],
  "459": ["NP", "NPL", "", "Nepal"],
  "461": ["OM", "OMN", "", "Oman"],
  "463": ["PK", "PAK", "", "Pakistan"],
  "466": ["QA", "QAT", "", "Qatar (State of)"],
  "468": ["SY", "SYR", "", "Syrian Arab Republic"],
  "470": ["AE", "ARE", "", "United Arab Emirates"],
  "472": ["TJ", "TJK", "", "Tajikistan"],
  "473": ["YE", "YEM", "", "Yemen"],
  "475": ["YE", "YEM", "", "Yemen"],
  "477": ["HK", "HKG", "", "Hong Kong"],
  "478": ["BA", "BIH", "", "Bosnia and Herzegovina"],
  "501": ["FR", "FRA", "AQ", "Adelie Land"],
  "503": ["AU", "AUS", "", "Australia"],
  "506": ["MM", "MMR", "", "Myanmar"],
  "508": ["BN", "BRN", "", "Brunei Darussalam"],
  "510": ["FM", "FSM", "", "Micronesia"],
  "511": ["PW", "PLW", "", "Palau"],
  "512": ["NZ", "NZL", "", "New Zealand"],
  "514": ["KH", "KHM", "", "Cambodia"],
  "515": ["KH", "KHM", "", "Cambodia"],
  "516": ["CX", "CXR", "", "Christmas Island"],
  "518": ["CK", "COK", "", "Cook Islands"],
  "520": ["FJ", "FJI", "", "Fiji"],
  "523": ["CC", "CCK", "", "Cocos (Keeling) Islands"],
  "525": ["ID", "IDN", "", "Indonesia"],
  "529": ["KI", "KIR", "", "Kiribati"],
  "531": ["LA", "LAO", "", "Lao People's Democratic Republic"],
  "533": ["MY", "MYS", "", "Malaysia"],
  "536": ["MP", "MNP", "", "Northern Mariana Islands"],
  "538": ["MH", "MHL", "", "Marshall Islands"],
  "540": ["NC", "NCL", "", "New Caledonia"],
  "542": ["NU", "NIU", "", "Niue"],
  "544": ["NR", "NRU", "", "Nauru"],
  "546": ["PF", "PYF", "", "French Polynesia"],
  "548": ["PH", "PHL", "", "Philippines"],
  "553": ["PG", "PNG", "", "Papua New Guinea"],
  "555": ["PN", "PCN", "", "Pitcairn Island"],
  "557": ["SB", "SLB", "", "Solomon Islands"],
  "559": ["AS", "ASM", "", "American Samoa"],
  "561": ["WS", "WSM", "", "Samoa"],
  "563": ["SG", "SGP", "", "Singapore"],
  "564": ["SG", "SGP", "", "Singapore"],
  "565": ["SG", "SGP", "", "Singapore"],
  "566": ["SG", "SGP", "", "Singapore"],
  "567": ["TH", "THA", "", "Thailand"],
  "570": ["TO", "TON", "", "Tonga"],
  "572": ["TV", "TUV", "", "Tuvalu"],
  "574": ["VN", "VNM", "", "Viet Nam"],
  "576": ["VU", "VUT", "", "Vanuatu"],
  "577": ["VU", "VUT", "", "Vanuatu"],
  "578": ["WF", "WLF", "", "Wallis and Futuna Islands"],
  "601": ["ZA", "ZAF", "", "South Africa"],
  "603": ["AO", "AGO", "", "Angola"],
  "605": ["DZ", "DZA", "", "Algeria"],
  "607": ["FR", "FRA", "TF", "Saint Paul and Amsterdam Islands"],
  "608": ["GB", "GBR", "AC", "Ascension Island"],
  "609": ["BI", "BDI", "", "Burundi"],
  "610": ["BJ", "BEN", "", "Benin"],
  "611": ["BW", "BWA", "", "Botswana"],
  "612": ["CF", "CAF", "", "Central African Republic"],
  "613": ["CM", "CMR", "", "Cameroon"],
  "615": ["CG", "COG", "", "Congo"],
  "616": ["KM", "COM", "", "Comoros"],
  "617": ["CV", "CPV", "", "Cabo Verde"],
  "618": ["FR", "FRA", "TF", "Crozet Archipelago"],
  "619": ["CI", "CIV", "", "Ivory Coast"],
  "620": ["KM", "COM", "", "Comoros"],
  "621": ["DJ", "DJI", "", "Djibouti"],
  "622": ["EG", "EGY", "", "Egypt"],
  "624": ["ET", "ETH", "", "Ethiopia"],
  "625": ["ER", "ERI", "", "Eritrea"],
  "626": ["GA", "GAB", "", "Gabonese Republic"],
  "627": ["GH", "GHA", "", "Ghana"],
  "629": ["GM", "GMB", "", "Gambia"],
  "630": ["GW", "GNB", "", "Guinea-Bissau"],
  "631": ["GQ", "GNQ", "", "Equatorial Guinea"],
  "632": ["GN", "GIN", "", "Guinea"],
  "633": ["BF", "BFA", "", "Burkina Faso"],
  "634": ["KE", "KEN", "", "Kenya"],
  "635": ["FR", "FRA", "TF", "Kerguelen Islands"],
  "636": ["LR", "LBR", "", "Liberia"],
  "637": ["LR", "LBR", "", "Liberia"],
  "638": ["SS", "SSD", "", "South Sudan"],
  "642": ["LY", "LBY", "", "Libya"],
  "644": ["LS", "LSO", "", "Lesotho"],
  "645": ["MU", "MUS", "", "Mauritius"],
  "647": ["MG", "MDG", "", "Madagascar"],
  "649": ["ML", "MLI", "", "Mali"],
  "650": ["MZ", "MOZ", "", "Mozambique"],
  "654": ["MR", "MRT", "", "Mauritania"],
  "655": ["MW", "MWI", "", "Malawi"],
  "656": ["NE", "NER", "", "Niger"],
  "657": ["NG", "NGA", "", "Nigeria"],
  "659": ["NA", "NAM", "", "Namibia"],
  "660": ["RE", "REU", "", "Reunion"],
  "661": ["RW", "RWA", "", "Rwanda"],
  "662": ["SD", "SDN", "", "Sudan"],
  "663": ["SN", "SEN", "", "Senegal"],
  "664": ["SC", "SYC", "", "Seychelles"],
  "665": ["SH", "SHN", "", "Saint Helena"],
  "666": ["SO", "SOM", "", "Somali Democratic Republic"],
  "667": ["SL", "SLE", "", "Sierra Leone"],
  "668": ["ST", "STP", "", "Sao Tome and Principe"],
  "669": ["SZ", "SWZ", "", "Swaziland"],
  "670": ["TD", "TCD", "", "Chad"],
  "671": ["TG", "TGO", "", "Togolese Republic"],
  "672": ["TN", "TUN", "", "Tunisian Republic"],
  "674": ["TZ", "TZA", "", "Tanzania"],
  "675": ["UG", "UGA", "", "Uganda"],
  "676": ["CD", "COD", "", "Democratic Republic of the Congo"],
  "677": ["TZ", "TZA", "", "Tanzania"],
  "678": ["ZM", "ZMB", "", "Zambia"],
  "679": ["ZW", "ZWE", "", "Zimbabwe"],
  "701": ["AR", "ARG", "", "Argentine Republic"],
  "710": ["BR", "BRA", "", "Brazil"],
  "720": ["BO", "BOL", "", "Bolivia"],
  "725": ["CL", "CHL", "", "Chile"],
  "730": ["CO", "COL", "", "Colombia"],
  "735": ["EC", "ECU", "", "Ecuador"],
  "740": ["FK", "FLK", "", "Falkland Islands"],
  "745": ["GF", "GUF", "", "Guiana"],
  "750": ["GY", "GUY", "", "Guyana"],
  "755": ["PY", "PRY", "", "Paraguay"],
  "760": ["PE", "PER", "", "Peru"],
  "765": ["SR", "SUR", "", "Suriname"],
  "770": ["UY", "URY", "", "Uruguay"],
  "775": ["VE", "VEN", "", "Venezuela"],
};

type CountryInfo = {
  alpha2: string;
  alpha3: string;
  name: string;
};

export function mid(code: string): CountryInfo | undefined {
  code = code.substring(0, 3);
  const entry = mids[code];
  if (entry) return {
    alpha2: entry[0],
    alpha3: entry[1],
    name: entry[3],
  };
  return undefined
}

export const NavStatusToString = (navstat: number) => {
  switch (navstat) {
    case 0:
      return "Under Way";
    case 1:
      return "At Anchor";
    case 2:
      return "No Command";
    case 3:
      return "Restricted";
    case 4:
      return "Constrained";
    case 5:
      return "Moored";
    case 6:
      return "Aground";
    case 7:
      return "Fishing";
    case 8:
      return "Sailing";
    case 14:
      return "AIS-SAR";
    default:
      return undefined;
  }
};

export const VesselTypeToString = (type: number) => {
  switch (type) {
    case 20:
    case 21:
    case 22:
    case 23:
    case 24:
    case 25:
    case 26:
    case 27:
    case 28:
    case 29:
      return "Wing in Ground";
    case 30:
      return "Fishing Vessel";
    case 31:
    case 32:
      return "Towing";
    case 33:
      return "Dredging / Underwater Operations";
    case 34:
      return "Diving Operations";
    case 35:
      return "Military Operations";
    case 36:
      return "Sailing Vessel";
    case 37:
      return "Pleasure Craft";
    case 40:
    case 41:
    case 42:
    case 43:
    case 44:
    case 45:
    case 46:
    case 47:
    case 48:
    case 49:
      return "High Speed Craft";
    case 50:
      return "Pilot Vessel";
    case 51:
      return "Search and Rescue Vessel";
    case 52:
      return "Tug";
    case 53:
      return "Port Tender";
    case 54:
      return "Anti-Pollution Equipment";
    case 55:
      return "Law Enforcement";
    case 56:
    case 57:
      return "Local Vessel";
    case 58:
      return "Medical Transport";
    case 59:
      return "Noncombatant (RR Resolution No. 18)";
    case 60:
    case 61:
    case 62:
    case 63:
    case 64:
    case 65:
    case 66:
    case 67:
    case 68:
    case 69:
      return "Passenger Vessel";
    case 70:
    case 71:
    case 72:
    case 73:
    case 74:
    case 75:
    case 76:
    case 77:
    case 78:
    case 79:
      return "Cargo Vessel";
    case 80:
    case 81:
    case 82:
    case 83:
    case 84:
    case 85:
    case 86:
    case 87:
    case 88:
    case 89:
      return "Tanker Vessel";
    default:
      return "Vessel (Other / Unknown)";
  }
};
