import { create } from "zustand";
import createFeatureSlice, { FeatureSlice } from "./feature.ts";
import createContextSlice, { ContextSlice } from "./context.ts";

const useApplicationStore = create<ContextSlice & FeatureSlice>()((...a) => ({
  ...createContextSlice(...a),
  ...createFeatureSlice(...a),
}));

export default useApplicationStore;
