import { StateCreator } from "zustand";
import { produce } from "immer";
import type { Activity, HotbarItem, Layer, Tool } from "./model.ts";
import type { ViewState } from "react-map-gl/maplibre";

export interface ContextSlice {
  tool: Tool;
  activity: Activity;
  hooked?: string | number;
  ballTabbed?: string | number;
  activeDragItem?: HotbarItem | undefined;
  view?: ViewState;
  layers: Layer[];
  layerVisibility: Record<string, boolean>;
  hotbarItems: Record<string | number, HotbarItem>;
  isOmnibarOpen: boolean;

  setHotbarItems: (items: Record<string, HotbarItem>) => void;
  addHotbarItem: (id: string | number, item: HotbarItem) => void;
  delHotbarItem: (id: string | number) => void;
  setActivity: (activity: Activity) => void;
  setTool: (tool: Tool) => void;
  setActiveDragItem: (item: HotbarItem | undefined) => void;
  setHooked: (hooked: string | number | undefined) => void;
  setBallTabbed: (ballTabbed: string | number | undefined) => void;
  clearHooked: () => void;
  setView: (view: ViewState) => void;
  toggleLayer: (layer: Layer) => void;
  setIsOmnibarOpen: (state: boolean) => void;
}

const createContextSlice: StateCreator<ContextSlice> = (set) => ({
  mapRef: null,
  activity: "map",
  tool: "select",
  activeDragItem: undefined,
  hooked: undefined,
  ballTabbed: undefined,
  view: undefined,
  layers: ["eez"],
  layerVisibility: {
    cables: false,
    eezau: true,
    eeznz: true,
    eezpac: true,
  },
  hotbarItems: {},
  isOmnibarOpen: false,

  setHotbarItems: (items) => {
    set(() => ({ hotbarItems: items }));
  },
  addHotbarItem: (id, item) => {
    set((state) => ({ hotbarItems: { ...state.hotbarItems, [id]: item } }));
  },
  delHotbarItem: (id) => {
    set(
      produce((state) => {
        delete state.hotbarItems[id];
      }),
    );
  },
  setActivity: (activity) => set({ activity }),
  setTool: (tool) => set({ tool }),
  setActiveDragItem: (item) => set({ activeDragItem: item }),
  setHooked: (hooked) => set({ hooked }),
  setBallTabbed: (ballTabbed) => set({ ballTabbed }),
  clearHooked: () => set({ hooked: undefined }),
  setView: (view) => set({ view }),
  toggleLayer: (layer) =>
    set(({ layers }) => {
      if (layers.includes(layer)) return { layers: layers.filter((element) => element !== layer) };
      else return { layers: [...layers, layer] };
    }),
  setIsOmnibarOpen: (state) => set({ isOmnibarOpen: state }),
});

export default createContextSlice;
