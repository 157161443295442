import qs from "query-string";

interface IGet {
  path: string;
  query?: object;
  signal?: AbortSignal;
  basePath?: string;
}

const get = async <T>({
  path,
  query = {},
  signal,
  basePath = import.meta.env.VITE_API_BASE_URL ?? "",
}: IGet): Promise<T> => {
  const queryString = `?${qs.stringify(query)}`;
  return new Promise((resolve, reject) => {
    fetch(`${basePath}${path}${queryString}`, {
      signal,
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      redirect: "follow",
      referrerPolicy: "no-referrer",
    })
      .then((response) => {
        if (response.ok) resolve(response.json());
        else reject(`Server returned error response with status code = ${response.status}.`);
      })
      .catch(reject);
  });
};

interface IPost {
  path: string;
  body?: Record<string, unknown>;
  signal?: AbortSignal;
  basePath?: string;
}

const post = async <T>({
  path,
  body,
  signal,
  basePath = import.meta.env.VITE_API_BASE_URL ?? "",
}: IPost): Promise<T> => {
  return new Promise((resolve, reject) => {
    fetch(`${basePath}${path}`, {
      signal,
      method: "POST",
      body: JSON.stringify(body),
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      redirect: "follow",
      referrerPolicy: "no-referrer",
    })
      .then((response) => {
        if (response.ok) resolve(response.json());
        else reject(`Server returned error response with status code = ${response.status}.`);
      })
      .catch(reject);
  });
};

const methods = {
  get,
  post,
};

export default methods;
