import methods from "./base";
import type { VesselCharacteristic } from "../stores/model";

export async function find(query: string, offset: number, limit?: number) {
  return await methods.post<VesselCharacteristic[]>({
    basePath: import.meta.env.VITE_V2_API_BASE_URL,
    path: "/v1/characteristics/search",
    body: {
      query: query,
      offset: offset,
      limit: limit,
    },
  });
}
