import { PropsWithChildren, useCallback, useRef, useState } from "react";
import { useInterval } from "usehooks-ts";
import Map, {
  Layer,
  MapLayerMouseEvent,
  MapRef,
  Source,
  ViewStateChangeEvent,
} from "react-map-gl/maplibre";

import type { Feature } from "geojson";
import bbox from "@turf/bbox";
import squareGrid from "@turf/square-grid";

import useApplicationStore from "../stores/application.ts";
import { type FeatureType, search } from "../service/feature.ts";
import type { FeatureCollection, Layer as MapLayer } from "../stores/model.ts";
import { batchTasks } from "../util/batch.ts";
import { selectionInner, selectionOuter } from "./layers/selection.ts";
import { au_eez_limit, nz_eez_limit, pac_eez_limit } from "./layers/eez";
import { submarine_cable_names, submarine_cables } from "./layers/subcables.ts";
import { hull, ais, vessel_flagged, vessel_simple } from "./layers/ais.ts";
import { adsb, adsb_emergency, adsb_simple, callsign } from "./layers/adsb.ts";
import { sar_simple } from "./layers/sar.ts";
import { drone } from "./layers/drone.ts";
import symbols from "./symbols";
import {viirs_af_simple} from "./layers/viirsaf.ts";

const TILE_SIZE = 1000;
const FETCH_BATCH_SIZE = 6;

let abortController = new AbortController();

const MissionMap = (props: PropsWithChildren) => {
  const mapRef = useRef<MapRef>(null);
  const [cursor, setCursor] = useState<string>("auto");
  const [hovered, setHovered] = useState<Feature>();
  const [loading, setLoading] = useState(false);
  const { view, setView } = useApplicationStore();
  const { layers } = useApplicationStore();
  const { features, insert } = useApplicationStore();
  const { hooked, setHooked, setBallTabbed } = useApplicationStore();

  const isVisible = useCallback((id: MapLayer) => layers.includes(id), [layers]);
  const trackLayers: Array<FeatureType> = ["adsb", "ais", "sar", "viirs-af", "drone"];

  // Refresh tracks displayed on map. Takes a flag indicating if in-flight requests for data should be cancelled.
  const refreshTracks = async (cancel: boolean = false) => {
    if (cancel && !abortController.signal.aborted) {
      abortController.abort("overcome by events");
      abortController = new AbortController();
    }

    if (!loading && (mapRef?.current && mapRef.current.getZoom() >= 4)) {
      setLoading(true);

      const { signal } = abortController;
      const bounds = mapRef.current!.getBounds();
      const ne = bounds.getNorthEast();
      const sw = bounds.getSouthWest();

      // Calculate tiles for current map view. Use map bounds if squareGrid result is zero.
      let tiles = squareGrid([sw.lng, sw.lat, ne.lng, ne.lat], TILE_SIZE).features.map(bbox);
      tiles = tiles.length !== 0 ? tiles : [[sw.lng, sw.lat, ne.lng, ne.lat]];

      for (const type of trackLayers) {
        const queries = tiles
          .map((bounds) => ({ type, bounds, fields: [], signal }))
          .map((params) => async () => search(params));

        try {
          for await (const batch of batchTasks(queries, FETCH_BATCH_SIZE)) {
            batch.forEach((c) => insert(c.features));
          }
        } catch (err) {
          if (err instanceof Error) {
            if (err.name !== "AbortError") console.error(err);
          }
        } finally {
          setLoading(false);
        }
      }
    }
  };

  // Refresh tracks shown on map periodically.
  useInterval(refreshTracks, 15000);

  const onLoad = () => {
    if (mapRef.current) {
      for (const key of Object.keys(symbols)) {
        const image = new Image();
        image.src = symbols[key]!;
        image.onload = () => mapRef.current?.getMap().addImage(key, image);
      }
    }
  };

  const handleViewStateChangedEvent = ({ viewState }: ViewStateChangeEvent) => setView(viewState);
  const handleSelection = (evt: MapLayerMouseEvent) =>
    setHooked(evt.features && evt.features[0] ? evt.features[0]?.id : undefined);
  const calculateSelectionFeatures: () => FeatureCollection = useCallback(() => {
    const feature = hooked ? features[hooked] : undefined;
    return {
      type: "FeatureCollection",
      features: feature ? [feature] : [],
    };
  }, [hooked, features]);

  const calculateTrackFeatures: () => FeatureCollection = useCallback(
    () => ({
      type: "FeatureCollection",
      features: Object.values(features),
    }),
    [features],
  );

  const onContextMenu = (evt: MapLayerMouseEvent) =>
    setBallTabbed(evt.features && evt.features[0] ? evt.features[0].id : undefined);

  const onMouseEnter = () => setCursor("pointer");
  const onMouseLeave = () => setCursor("auto");
  const onDragStart = () => setCursor("grabbing");
  const onDragEnd = () => setCursor("auto");

  const onMouseMove = (evt: MapLayerMouseEvent) => {
    const feature = evt.features && evt.features[0] ? evt.features[0] : undefined;
    if (hovered !== feature) {
      if (hovered) evt.target.removeFeatureState({ source: "tracks", id: hovered.id }, "hover");

      if (feature) {
        evt.target.setFeatureState({ source: "tracks", id: feature.id }, { hover: true });
        setHovered(feature);
      }
    }
  };

  return (
    <Map
      ref={mapRef}
      {...view}
      mapStyle="https://api.maptiler.com/maps/544d2b8f-d6e4-43d7-8ea4-0fe73d4001d6/style.json?key=KwNRfEqU8x9OE6WbBQd0"
      interactiveLayerIds={trackLayers}
      onLoad={onLoad}
      onClick={handleSelection}
      onMove={handleViewStateChangedEvent}
      onZoom={handleViewStateChangedEvent}
      onMoveEnd={() => refreshTracks(true)}
      onZoomEnd={() => refreshTracks(true)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onContextMenu={onContextMenu}
      onMouseMove={onMouseMove}
      cursor={cursor}
      attributionControl={false}
    >
      {isVisible("eez") && (
        <>
          <Source id="au_eez_limit" type="geojson" data="au_eez_limit.geojson">
            <Layer {...au_eez_limit} />
          </Source>

          <Source id="nz_eez_limit" type="geojson" data="nz_eez_limit.geojson">
            <Layer {...nz_eez_limit} />
          </Source>
          <Source id="pac_eez_limit" type="geojson" data="pac_eez_limit.geojson">
            <Layer {...pac_eez_limit} />
          </Source>
        </>
      )}

      {isVisible("cable") && (
        <Source id="submarine-cables" type="geojson" data="submarine-cables.geojson">
          <Layer {...submarine_cables} />
          <Layer {...submarine_cable_names} />
        </Source>
      )}

      <Source id="tracks" type="geojson" data={calculateTrackFeatures()}>
        <Layer {...ais} />
        <Layer {...vessel_simple} />
        <Layer {...vessel_flagged} />
        <Layer {...hull} />

        <Layer {...adsb} />
        <Layer {...adsb_simple} />
        <Layer {...adsb_emergency} />
        <Layer {...callsign} />

        <Layer {...sar_simple} />

        <Layer {...viirs_af_simple}/>

        <Layer {...drone} />
      </Source>

      <Source id="selection" type="geojson" data={calculateSelectionFeatures()}>
        <Layer {...selectionInner} />
        <Layer {...selectionOuter} />
      </Source>

      {props.children}
    </Map>
  );
};
export default MissionMap;
