import type { LayerProps } from "react-map-gl/maplibre";

export const drone: LayerProps = {
  id: "drone",
  type: "symbol",
  filter: ["==", ["get", "sensor"], "drone"],
  layout: {
    "icon-allow-overlap": true,
    "icon-image": "uav",
    "icon-size": 1,
    "icon-pitch-alignment": "map",
    "icon-rotation-alignment": "map",
    "icon-rotate": {
      type: "identity",
      property: "heading", // TODO: verify
    },
  },
};
