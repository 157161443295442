import type { LayerProps } from "react-map-gl/maplibre";

export const adsb: LayerProps = {
  id: "adsb",
  type: "symbol",
  filter: ["==", ["get", "sensor"], "adsb"],
  minzoom: 8,
  layout: {
    "icon-optional": false,
    "icon-allow-overlap": true,
    // prettier-ignore
    "icon-image": [
      "image",
      [
        "step",
        ["to-number", ["get", "category"]], // ads-b category (none or null evaluate to 0)
        "medium",               // | 0  | No information at all
        1, "medium",            // | 1  | No ADS-B Emitter Category Information
        2, "light",             // | 2  | Light (<15,500 lbs)
        3, "small",             // | 3  | Small (15,500 to 75,000 lbs)
        4, "medium",            // | 4  | Large (75,000 to 300,000 lbs)
        5, "medium",            // | 5  | High Vortex Large
        6, "heavy",             // | 6  | Heavy (>300,000 lbs)
        7, "high-performance",  // | 7  | High Performance (>5g acceleration and 400 kts)
        8, "rotorcraft",        // | 8  | Rotorcraft
        9, "ultralight",        // | 9  | Glider / sailplane
        10, "lighter-than-air", // | 10 | Lighter-than-air
        11, "ultralight",       // | 11 | Parachutist / Skydiver
        12, "ultralight",       // | 12 | Ultralight
        13, "medium",           // | 13 | Reserved
        14, "uav",              // | 14 | Unmanned Aerial Vehicle
        15, "high-performance", // | 15 | Space / Trans-atmospheric vehicle
        16, "surface-vehicle",  // | 16 | Surface Vehicle - Emergency Vehicle
        17, "surface-vehicle",  // | 17 | Surface Vehicle - Service Vehicle
        18, "lighter-than-air", // | 18 | Point Obstacle (includes tethered balloons)
        19, "lighter-than-air", // | 19 | Cluster Obstacle
        20, "lighter-than-air"  // | 20 | Line Obstacle
      ]
    ],
    "icon-size": 0.7,
    "icon-pitch-alignment": "map",
    "icon-rotation-alignment": "map",
    "icon-rotate": {
      type: "identity",
      property: "true_track",
    },
  },
};

export const adsb_simple: LayerProps = {
  id: "adsb-simple",
  type: "circle",
  filter: ["==", ["get", "sensor"], "adsb"],
  minzoom: 4,
  maxzoom: 8,
  paint: {
    "circle-radius": ["interpolate", ["linear"], ["zoom"], 5, 1.5, 12, 3],
    "circle-color": "#fbb03b",
    "circle-stroke-width": ["interpolate", ["linear"], ["zoom"], 5, 0.0, 14, 10],
    "circle-stroke-color": "#f8d24a",
    "circle-stroke-opacity": ["case", ["boolean", ["feature-state", "hover"], false], 0.15, 0.05],
  },
};

export const adsb_emergency: LayerProps = {
  id: "adsb-emergency",
  type: "circle",
  filter: [
    "all",
    ["==", ["get", "sensor"], "ais"],
    [
      "any",
      ["==", ["get", "squawk"], "7500"],
      ["==", ["get", "squawk"], "7600"],
      ["==", ["get", "squawk"], "7700"],
    ],
  ],
  minzoom: 4,
  paint: {
    "circle-radius": 12,
    "circle-color": "#ff6347",
    "circle-opacity": 0.5,
    "circle-stroke-color": "#ff6347",
    "circle-stroke-opacity": 0.2,
    "circle-stroke-width": 4,
  },
};

export const callsign: LayerProps = {
  id: "adsb-callsign",
  type: "symbol",
  filter: ["==", ["get", "sensor"], "adsb"],
  minzoom: 9,
  layout: {
    "text-allow-overlap": true,
    "text-field": "{callsign}",
    "text-size": 12,
    "text-offset": [2.7, -0.6],
    "text-anchor": "bottom",
  },
  paint: {
    "text-color": "#FFF",
  },
};
