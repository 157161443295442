import { useCallback, memo } from "react";
import { useQuery } from "@tanstack/react-query";
import { DndContext, DragEndEvent, DragOverlay, DragStartEvent, useDroppable } from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  horizontalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { restrictToHorizontalAxis, restrictToWindowEdges } from "@dnd-kit/modifiers";
import { CSS } from "@dnd-kit/utilities";
import { useMap } from "react-map-gl/maplibre";
import { DateTime } from "luxon";
import type { GeoJsonProperties, Point } from "geojson";

import { NavStatusToString } from "../util/ais";
import useApplicationStore from "../stores/application";
import { get } from "../service/feature";
import type { Feature, HotbarItem } from "../stores/model";
import type { Mission } from "../service/mission";

import {
  Button,
  Classes,
  Icon,
  IconName,
  ProgressBar,
  Section,
  SectionCard,
} from "@blueprintjs/core";
import AircraftTag from "./AircraftTag";

const getHeaderElementsOfSensor = (properties: GeoJsonProperties) => {
  const icon =
    properties?.sensor === "ais"
      ? "ship"
      : properties?.sensor === "adsb"
      ? "airplane"
      : "satellite";
  const title =
    properties?.sensor === "ais"
      ? properties?.name || "UNKNOWN"
      : properties?.sensor === "adsb"
      ? properties?.callsign
      : "SAR Detection";
  return { title, icon };
};

const MissionHotbarItemBody = ({ mission }: { mission: Mission }) => {
  return (
    <SectionCard>
      <div className="grid grid-cols-3 gap-y-2 text-xs">
        <div className="col-span-2">
          <span className={Classes.TEXT_MUTED}>Id</span>
          <p>{mission.id}</p>
        </div>
        <div>
          <span className={Classes.TEXT_MUTED}>Status</span>
          <p>{mission.status}</p>
        </div>
        <div className="col-span-3">
          <span className={Classes.TEXT_MUTED}>Progress</span>
          <ProgressBar
            value={mission.progress || 0.0}
            intent={mission.status == "In Progress" ? "success" : "none"}
            stripes={mission.status === "Ordered" || mission.status === "In Progress"}
          />
        </div>
      </div>
    </SectionCard>
  );
};

type MissionItemProps = {
  mission: Mission;
  onClose: (id: string) => void;
};

const MissionHotbarItem = ({ mission, onClose }: MissionItemProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: mission.id,
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Section
      ref={setNodeRef}
      title={
        <div className="flex items-center gap-2" {...attributes} {...listeners}>
          <Icon icon="playbook" className={Classes.TEXT_MUTED} />
          {mission.name}
        </div>
      }
      rightElement={<Button icon="cross" minimal onClick={() => onClose(mission.id)} />}
      style={style}
      className="h-full"
      compact
    >
      <MissionHotbarItemBody mission={mission} />
    </Section>
  );
};

type FeatureItemBodyProps = {
  feature: Feature;
  handleGoTo?: (feature: Feature) => void;
};

const FeatureHotbarItemBody = (props: FeatureItemBodyProps) => {
  const { data: ft } = useQuery({
    queryKey: [props.feature.id],
    refetchInterval: 15000, // seconds
    queryFn: async () => {
      const ftProps = props.feature.properties;
      const id =
        ftProps?.sensor === "ais"
          ? ftProps.mmsi
          : ftProps?.sensor === "adsb"
          ? ftProps.icao24
          : ftProps?.id;
      return get(ftProps?.sensor, id);
    },
    initialData: props.feature,
  });

  const { properties } = ft;
  const [longitude, latitude] = (ft.geometry as Point).coordinates;

  switch (properties?.sensor) {
    case "ais": {
      const navstat = NavStatusToString(properties?.navstat);
      return (
        <SectionCard>
          <div className="grid grid-cols-3 gap-y-2 text-xs">
            <div className="col-span-3">
              <span className={Classes.TEXT_MUTED}>Position</span>
              <p>
                <button
                  onClick={() => props.handleGoTo && props.handleGoTo(ft)}
                  className="hover:underline"
                >
                  {latitude?.toFixed(5)}, {longitude?.toFixed(5)}
                </button>
              </p>
            </div>
            <div>
              <span className={Classes.TEXT_MUTED}>Speed</span>
              <p>{properties?.sog ? `${(properties.sog / 10.0).toFixed(2)} kn` : "-"}</p>
            </div>
            <div>
              <span className={Classes.TEXT_MUTED}>Course</span>
              <p>{properties?.cog ? `${properties.cog}°` : "-"}</p>
            </div>
            <div>
              <span className={Classes.TEXT_MUTED}>Status</span>
              <p>{navstat || "-"}</p>
            </div>
          </div>
        </SectionCard>
      );
    }
    case "adsb":
      return (
        <SectionCard>
          <div className="grid grid-cols-3 gap-y-2 text-xs">
            <div className="col-span-2">
              <span className={Classes.TEXT_MUTED}>Position</span>
              <p>
                <button
                  onClick={() => props.handleGoTo && props.handleGoTo(ft)}
                  className="hover:underline"
                >
                  {latitude?.toFixed(5)}, {longitude?.toFixed(5)}
                </button>
              </p>
            </div>
            <div>
              <span className={Classes.TEXT_MUTED}>Heading</span>
              <p>{properties?.true_track ? `${properties.true_track}°` : "-"}</p>
            </div>
            <div>
              <span className={Classes.TEXT_MUTED}>Speed</span>
              <p>{properties?.velocity ? `${properties.velocity} m/s` : "-"}</p>
            </div>
            <div>
              <span className={Classes.TEXT_MUTED}>Altitude</span>
              <p>{properties?.baro_altitude ? `${properties.baro_altitude} m` : "-"}</p>
            </div>
            <div>
              <span className={Classes.TEXT_MUTED}>Squawk</span>
              <p>{properties?.squawk ? `${properties.squawk}` : "-"}</p>
            </div>
            <div>{properties.squawk && <AircraftTag squawk={properties.squawk} />}</div>
          </div>
        </SectionCard>
      );
    case "sar":
      return (
        <SectionCard>
          <div className="grid grid-cols-3 gap-y-2 text-xs">
            <div className="col-span-3">
              <span className={Classes.TEXT_MUTED}>Position</span>
              <p>
                <button
                  onClick={() => props.handleGoTo && props.handleGoTo(ft)}
                  className="hover:underline"
                >
                  {latitude?.toFixed(5)}, {longitude?.toFixed(5)}
                </button>
              </p>
            </div>
            <div className="col-span-3">
              <span className={Classes.TEXT_MUTED}>Timestamp</span>
              <p>{DateTime.fromISO(properties.timestamp).toLocaleString(DateTime.DATETIME_FULL)}</p>
            </div>
          </div>
        </SectionCard>
      );
  }
};

type FeatureItemProps = {
  feature: Feature;
  onClose: (id: string) => void;
  handleGoTo: (feature: Feature) => void;
};

const FeatureHotbarItem = memo(({ feature, onClose, handleGoTo }: FeatureItemProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: feature.id ?? -1,
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const { properties } = feature;
  const { icon, title } = getHeaderElementsOfSensor(properties);

  return (
    <Section
      ref={setNodeRef}
      title={
        <div className="flex cursor-grab items-center gap-2" {...attributes} {...listeners}>
          <Icon icon={icon as IconName} className={Classes.TEXT_MUTED} />
          {title || "UNKNOWN"}
        </div>
      }
      rightElement={<Button icon="cross" minimal onClick={() => onClose(feature.id as string)} />}
      style={style}
      className="h-full"
      compact
    >
      <FeatureHotbarItemBody feature={feature} handleGoTo={handleGoTo} />
    </Section>
  );
});

type HotbarItemFactoryProps = {
  item: HotbarItem;
  isDragging: boolean;
  onClose: (id: string) => void;
  handleGoTo: (feature: Feature) => void;
};

const HotbarItemFactory = memo(
  ({ item, isDragging, onClose, handleGoTo }: HotbarItemFactoryProps) => {
    switch (item.kind) {
      case "mission":
        return (
          <div className="relative">
            {isDragging && <div className="absolute z-20 h-full w-full bg-primary/40" />}
            <div className="h-full w-72 shadow">
              <MissionHotbarItem mission={item.mission} onClose={onClose} />
            </div>
          </div>
        );
      case "feature":
        return (
          <div className="relative">
            {isDragging && <div className="absolute z-20 h-full w-full bg-primary/40" />}
            <div className="h-full w-72 shadow">
              <FeatureHotbarItem feature={item.feature} onClose={onClose} handleGoTo={handleGoTo} />
            </div>
          </div>
        );
      default:
        return null;
    }
  },
);

const DragItemPreview = ({ item }: { item: HotbarItem }) => {
  // Item is mission
  if (item.kind === "mission") {
    return (
      <div className="h-full w-72 cursor-grabbing">
        <Section
          title={
            <div className="flex items-center gap-2">
              <Icon icon="playbook" className={Classes.TEXT_MUTED} />
              {item.mission.name}
            </div>
          }
          rightElement={<Button icon="cross" minimal />}
          compact
          className="h-full"
        >
          <MissionHotbarItemBody mission={item.mission} />
        </Section>
      </div>
    );
  }

  // Item is feature
  const { properties } = item.feature;
  const { icon, title } = getHeaderElementsOfSensor(properties);
  return (
    <div className="h-full w-72 cursor-grabbing">
      <Section
        title={
          <div className="flex items-center gap-2">
            <Icon icon={icon as IconName} className={Classes.TEXT_MUTED} />
            {title}
          </div>
        }
        rightElement={<Button icon="cross" minimal />}
        className="h-full"
        compact
      >
        <FeatureHotbarItemBody feature={item.feature} />
      </Section>
    </div>
  );
};

const Hotbar = () => {
  const map = useMap();
  const {
    setHooked,
    hotbarItems,
    delHotbarItem,
    setHotbarItems,
    activeDragItem,
    setActiveDragItem,
  } = useApplicationStore();

  const { setNodeRef, isOver } = useDroppable({ id: "hotbar" });

  const handleDragStart = useCallback(
    ({ active }: DragStartEvent) => {
      if (!active) return;
      setActiveDragItem(hotbarItems[active.id]);
    },
    [hotbarItems, setActiveDragItem],
  );

  const handleDragEnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      setActiveDragItem(undefined);
      if (over && active.id !== over.id) {
        const keys = Object.keys(hotbarItems);
        const oldIdx = keys.indexOf(active.id.toString());
        const newIdx = keys.indexOf(over.id.toString());

        const newOrder = arrayMove(keys, oldIdx, newIdx).reduce(
          (prev, cur) => ({ ...prev, [cur]: hotbarItems[cur] }),
          {},
        );
        setHotbarItems(newOrder);
      }
    },
    [hotbarItems, setHotbarItems, setActiveDragItem],
  );

  const handleGoTo = (feature: Feature) => {
    if (!map.current) return;
    const [longitude, latitude] = (feature.geometry as Point).coordinates;
    setHooked(feature.id);
    map.current.panTo([longitude!, latitude!]);
  };

  return (
    <div
      ref={setNodeRef}
      className={`absolute bottom-0 left-0 m-2 ${isOver ? "bg-white/10" : ""} ${
        activeDragItem ? "w-full" : ""
      } ${activeDragItem && Object.keys(hotbarItems).length === 0 ? "p-20" : ""}`}
    >
      <DndContext
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToHorizontalAxis, restrictToWindowEdges]}
      >
        <SortableContext items={Object.keys(hotbarItems)} strategy={horizontalListSortingStrategy}>
          <div
            className={`flex gap-2 ${
              Object.keys(hotbarItems).length > 6 ? "w-[100vw] overflow-x-scroll" : ""
            }`}
          >
            {Object.entries(hotbarItems).map(([id, item]) => (
              <HotbarItemFactory
                key={id}
                item={item}
                isDragging={typeof activeDragItem !== "undefined"}
                onClose={delHotbarItem}
                handleGoTo={handleGoTo}
              />
            ))}
          </div>
        </SortableContext>
        <DragOverlay>
          {activeDragItem ? <DragItemPreview item={activeDragItem} /> : null}
        </DragOverlay>
      </DndContext>
    </div>
  );
};

export default Hotbar;
