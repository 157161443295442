import { DateTime } from "luxon";
import type { Feature, Point } from "geojson";
import { Callout, Classes, SectionCard } from "@blueprintjs/core";
import Chip from "../Chip.tsx";
import { StyleInjector } from "../loading.tsx";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

export const SarPanelHeader = () => {
  return (
    <Callout title="Vessel Detected by SAR">
      <p>ESA Sentinel-1</p>
    </Callout>
  );
};

type SarPanelProps = {
  feature: Feature;
  handleGoTo: () => void;
  isLoading: boolean;
};

export const SarDataPanel = (props: SarPanelProps) => {
  const { properties } = props.feature;
  const [longitude, latitude] = (props.feature.geometry as Point).coordinates;
  const reactQueryClient = new QueryClient();

  return (
    <QueryClientProvider client={reactQueryClient}>
      <StyleInjector isLoading={props.isLoading}>
        <SectionCard className="grid grid-cols-3 gap-y-2 text-xs">
          <div className="col-span-3 h-[270px]">
            {properties?.id && <Chip id={properties.id} width={270} height={270} />}
          </div>
          <div className="col-span-3">
            <span className={Classes.TEXT_MUTED}>Position</span>
            <p>
              <button onClick={props.handleGoTo} className="hover:underline">
                {latitude?.toFixed(5)}, {longitude?.toFixed(5)}
              </button>
            </p>
          </div>
          <div className="col-span-3">
            <span className={Classes.TEXT_MUTED}>Timestamp</span>
            <p>
              {properties?.timestamp
                ? DateTime.fromISO(properties.timestamp).toLocaleString(DateTime.DATETIME_FULL)
                : "-"}
            </p>
          </div>
          <div>
            <span className={Classes.TEXT_MUTED}>Est. Length</span>
            <p>{properties?.length} m</p>
          </div>
          <div className="col-span-2">
            <span className={Classes.TEXT_MUTED}>Est. Width</span>
            <p>{properties?.width} m</p>
          </div>
          <div className="col-span-3">
            <span className={Classes.TEXT_MUTED}>Product</span>
            <p>{properties?.product ? properties.product.slice(0, 25) : "-"}</p>
          </div>
        </SectionCard>
      </StyleInjector>
    </QueryClientProvider>
  );
};
