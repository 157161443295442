import { DateTime } from "luxon";
import { useQuery } from "@tanstack/react-query";
import type { Feature, Point } from "geojson";

import { NavStatusToString, VesselTypeToString, mid } from "../../util/ais.ts";

import {
  Callout,
  Classes,
  HTMLTable,
  NonIdealState,
  NonIdealStateIconSize,
  SectionCard,
  Spinner,
} from "@blueprintjs/core";
import CollapsableSectionCard from "../CollapsableSectionCard";
import VesselTag from "../VesselTag.tsx";
import { StyleInjector } from "../loading.tsx";

import "/node_modules/flag-icons/css/flag-icons.min.css";
import client from "../../client";
import { getCountryISO2 } from "../../util/country-flag.ts";

type PortVisit = {
  visitId: string;
  durationHrs: number;
  confidence: number;
  startAnchorage: {
    id: string;
    lat: number;
    lon: number;
    flag: string;
    name: string;
    at_dock: boolean;
    anchorage_id: string;
    top_destination: string;
    distance_from_shore_km: number;
  };
  intermediateAnchorage: {
    id: string;
    lat: number;
    lon: number;
    flag: string;
    name: string;
    at_dock: boolean;
    anchorage_id: string;
    top_destination: string;
    distance_from_shore_km: number;
  };
  endAnchorage: {
    id: string;
    lat: number;
    lon: number;
    flag: string;
    name: string;
    at_dock: boolean;
    anchorage_id: string;
    top_destination: string;
    distance_from_shore_km: number;
  };
};

function PortCalls(props: { mmsi: string }) {
  const visits = useQuery({
    queryKey: [props.mmsi],
    staleTime: 1000 * 60 * 60, // invalidate after 1 hour otherwise use cached values
    queryFn: async () => {
      const vesselId = await client
        .GET("/v2/vessels/search", {
          params: {
            query: {
              query: props.mmsi,
              limit: 1,
              offset: 0,
              datasets:
                "public-global-support-vessels:latest,public-global-carrier-vessels:latest,public-global-fishing-vessels:latest",
            },
          },
        })
        .then(({ data, error }) => {
          if (data) return data.entries.at(0)?.id;
          throw Error(error.error);
        });

      if (!vesselId) return [];

      return await client
        .GET("/v2/events", {
          params: {
            query: {
              datasets: "public-global-port-visits-c2-events:latest",
              vessels: vesselId,
              types: "port_visit",
              limit: 5,
              offset: 0,
              sort: "-start",
            },
          },
        })
        .then(({ data, error }) => {
          if (data)
            return data.entries.map(
              (entry) =>
                entry as (typeof data.entries)[number] & {
                  port_visit: PortVisit;
                },
            );
          throw Error(error.error);
        });
    },
  });

  // Return loading panel while fetching.
  if (visits.isLoading)
    return (
      <SectionCard>
        <NonIdealState
          icon={<Spinner />}
          iconSize={NonIdealStateIconSize.EXTRA_SMALL}
          description="Fetching port calls..."
        />
      </SectionCard>
    );

  // Return error panel if fetch failed.
  if (visits.error)
    return (
      <SectionCard>
        <NonIdealState
          icon="error"
          iconSize={NonIdealStateIconSize.STANDARD}
          title="Error"
          description="Failed to fetch port calls. Please try again later."
        />
      </SectionCard>
    );

  // Return data panel if fetch succeeded and data is available.
  if (visits.data && visits.data.length != 0)
    return (
      <HTMLTable compact className="w-full">
        <thead>
          <tr>
            <th>Recent Port Calls</th>
            <th>Time of Arrival</th>
          </tr>
        </thead>
        <tbody>
          {visits?.data?.map(({ start, port_visit }, idx) => (
            <tr key={idx}>
              <td className="flex gap-1 text-xs">
                <span
                  className={`fi fi-${getCountryISO2(
                    port_visit.startAnchorage.flag,
                  )?.toLowerCase()}`}
                />
                {port_visit?.startAnchorage?.name !== "" ? port_visit?.startAnchorage?.name : "N/a"}
              </td>
              <td className="text-xs">
                {DateTime.fromISO(start).toLocaleString(DateTime.DATETIME_SHORT)}
              </td>
            </tr>
          ))}
        </tbody>
      </HTMLTable>
    );

  // Return no data panel if fetch succeeded but no data was available.
  return (
    <SectionCard>
      <NonIdealState description="No port calls." />
    </SectionCard>
  );
}

type PanelProps = {
  feature: Feature;
  isLoading: boolean;
};

export const AisPanelHeader = (props: PanelProps) => {
  const { properties } = props.feature;
  return (
    <StyleInjector isLoading={props.isLoading}>
      <Callout intent="none">
        <h5 className={Classes.HEADING}>{properties?.name || "UNKNOWN NAME"}</h5>
        <span className="float-right">
          <VesselTag type={properties?.type} />
        </span>
        <p>{VesselTypeToString(properties?.type)}</p>
      </Callout>
    </StyleInjector>
  );
};

type DataPanelProps = PanelProps & {
  handleGoTo: () => void;
};

export const AisDataPanel = (props: DataPanelProps) => {
  const { properties } = props.feature;
  const navstat = NavStatusToString(properties?.navstat);
  const [longitude, latitude] = (props.feature.geometry as Point).coordinates;
  const country = mid(String(props.feature.properties?.mmsi));

  return (
    <>
      <StyleInjector isLoading={props.isLoading}>
        <SectionCard>
          <div className="grid grid-cols-3 gap-y-2 text-xs">
            <div className="col-span-3">
              <span className={Classes.TEXT_MUTED}>Position</span>
              <p>
                <button onClick={props.handleGoTo} className="hover:underline">
                  {latitude?.toFixed(5)}, {longitude?.toFixed(5)}
                </button>
              </p>
            </div>
            <div>
              <span className={Classes.TEXT_MUTED}>Speed</span>
              <p>{properties?.sog ? `${(properties.sog / 10.0).toFixed(2)} kn` : "-"}</p>
            </div>
            <div>
              <span className={Classes.TEXT_MUTED}>Course</span>
              <p>{properties?.cog ? `${properties.cog}°` : "-"}</p>
            </div>
            <div>
              <span className={Classes.TEXT_MUTED}>Draught</span>
              <p>{properties?.draught ? `${(properties.draught / 100.0).toFixed(2)} m` : "-"}</p>
            </div>
            <div>
              <span className={Classes.TEXT_MUTED}>Status</span>
              <p>{navstat || "-"}</p>
            </div>
            <div className="col-span-2">
              <span className={Classes.TEXT_MUTED}>Flag</span>
              {country ? (
                <p className="flex gap-1">
                  {country.name}
                  <span className={`fi fi-${country.alpha2.toLowerCase()}`} />
                </p>
              ) : (
                <p>-</p>
              )}
            </div>
            <div>
              <span className={Classes.TEXT_MUTED}>Callsign</span>
              <p>{properties?.callsign ? `${properties?.callsign}` : "-"}</p>
            </div>
            <div className="col-span-2">
              <span className={Classes.TEXT_MUTED}>Last Report</span>
              <p>
                {properties?.timestamp
                  ? DateTime.fromSeconds(properties.timestamp).toLocaleString(
                      DateTime.DATETIME_FULL,
                    )
                  : "-"}
              </p>
            </div>
          </div>
        </SectionCard>
        <SectionCard>
          <div className="grid grid-cols-3 gap-y-2 text-xs">
            <div>
              <span className={Classes.TEXT_MUTED}>Gross Tonnage</span>
              <p>-</p>
            </div>
            <div>
              <span className={Classes.TEXT_MUTED}>Built</span>
              <p>-</p>
            </div>
            <div>
              <span className={Classes.TEXT_MUTED}>IMO</span>
              <p>{properties?.imo ? `${properties?.imo}` : "-"}</p>
            </div>
            <div>
              <span className={Classes.TEXT_MUTED}>Deadweight</span>
              <p>-</p>
            </div>
            <div>
              <span className={Classes.TEXT_MUTED}>Length / Beam</span>
              <p>
                {properties?.bow && properties?.stern && properties?.port && properties?.starboard
                  ? `${Math.max(properties.bow, properties.stern)} / ${Math.max(
                      properties.port,
                      properties.starboard,
                    )} m`
                  : "-"}
              </p>
            </div>
            <div>
              <span className={Classes.TEXT_MUTED}>MMSI</span>
              <p>{properties?.mmsi}</p>
            </div>
          </div>
        </SectionCard>
      </StyleInjector>
      <CollapsableSectionCard title="Port Calls" icon="anchor" padded={false} defaultIsOpen={false}>
        <PortCalls mmsi={properties?.mmsi} />
      </CollapsableSectionCard>
    </>
  );
};
