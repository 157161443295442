import { useCallback, useMemo, useContext } from "react";
import { DndContext, DragStartEvent, type DragEndEvent, DragOverlay } from "@dnd-kit/core";
import { restrictToWindowEdges } from "@dnd-kit/modifiers";

import { ThemeSelectionContext } from "../Context.ts";
import useBodyClassname from "../util/body.ts";
import useApplicationStore from "../stores/application.ts";
import type { HotbarItem } from "../stores/model.ts";

import { Button, HotkeysProvider, OverlaysProvider } from "@blueprintjs/core";
import Header from "../components/Header.tsx";
import FeatureContextMenu from "../components/FeatureContextMenu.tsx";
import MissionMap from "../components/MissionMap";
import FeatureSelection from "../components/FeatureSelection";
import FeaturePanel, { PanelHeaderFactory } from "../components/track/FeaturePanel.tsx";
import MissionPanel, { MissionStatusTag } from "../components/tasking/MissionPanel.tsx";
import BehaviorPanel from "../components/BehaviorPanel.tsx";
import TaskingDialog from "../components/tasking/TaskingDialog.tsx";
import Hotbar from "../components/Hotbar";
import SearchOmnibar from "../components/SearchOmnibar.tsx";

const DragOverlayItem = () => {
  const { activeDragItem } = useApplicationStore();
  switch (activeDragItem?.kind) {
    case "feature":
      return (
        <div className="cursor-grabbing">
          <PanelHeaderFactory feature={activeDragItem.feature} isLoading={false} />
        </div>
      );
    case "mission":
      return (
        <div className="flex w-72 bg-tertiary px-2 py-1">
          <Button icon="drag-handle-vertical" minimal />
          <div className="flex w-full items-center justify-between">
            <p>{activeDragItem.mission.name}</p>
            <MissionStatusTag status={activeDragItem.mission.status} />
          </div>
        </div>
      );
    default:
      return null;
  }
};

const App = () => {
  const theme = useContext(ThemeSelectionContext);
  const {
    activity,
    setActivity,
    setActiveDragItem,
    hooked,
    ballTabbed,
    features,
    addHotbarItem,
    isOmnibarOpen,
  } = useApplicationStore();

  // Theme selection
  useBodyClassname([`bp5-${theme}`, "bg-primary"]);

  const selectedFeature = useMemo(() => {
    return hooked ? features[hooked] : undefined;
  }, [features, hooked]);

  const handleDragStart = useCallback(
    ({ active }: DragStartEvent) => {
      if (!active) return;
      setActiveDragItem(active.data.current as HotbarItem);
    },
    [setActiveDragItem],
  );

  const handleDragEnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      setActiveDragItem(undefined);
      if (over && over.id === "hotbar") {
        addHotbarItem(active.id, active.data.current as HotbarItem);
      }
    },
    [addHotbarItem, setActiveDragItem],
  );

  return (
    <OverlaysProvider>
      <HotkeysProvider>
        <div className="flex h-screen w-full flex-col">
          <Header />
          <TaskingDialog isOpen={activity === "mission"} onClose={() => setActivity("map")} />
          <div className="relative h-full overflow-hidden">
            <DndContext
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
              modifiers={[restrictToWindowEdges]}
            >
              <DragOverlay dropAnimation={null}>
                {!isOmnibarOpen && <DragOverlayItem />}
              </DragOverlay>
              <MissionMap>
                <SearchOmnibar />
                {selectedFeature && (
                  <FeatureSelection>
                    <FeaturePanel feature={selectedFeature} />
                    <BehaviorPanel feature={selectedFeature} />
                    <MissionPanel feature={selectedFeature} />
                  </FeatureSelection>
                )}
                {ballTabbed && <FeatureContextMenu />}
                <Hotbar />
              </MissionMap>
            </DndContext>
          </div>
        </div>
      </HotkeysProvider>
    </OverlaysProvider>
  );
};

export default App;
