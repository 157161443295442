import { memo, useCallback } from "react";
import { useMap } from "react-map-gl";
import { useDraggable } from "@dnd-kit/core";
import { Callout, NonIdealState } from "@blueprintjs/core";
import { useQuery } from "@tanstack/react-query";

import type { Point } from "geojson";
import type { Feature } from "../../stores/model.ts";

import { get } from "../../service/feature.ts";

import { AisPanelHeader, AisDataPanel } from "./AisDataPanel.tsx";
import { AdsbPanelHeader, AdsbDataPanel } from "./AdsbDataPanel.tsx";
import { SarPanelHeader, SarDataPanel } from "./SarDataPanel.tsx";
import { ViirsAfPanelHeader, ViirsAfDataPanel } from "./ViirsAfDataPanel.tsx";
import { DronePanelHeader, DroneDataPanel } from "./DroneDataPanel.tsx";

type FactoryProps = {
  feature: Feature;
  isLoading: boolean;
};

export const PanelHeaderFactory = (props: FactoryProps) => {
  switch (props.feature.properties?.sensor) {
    case "ais":
      return <AisPanelHeader {...props} />;
    case "adsb":
      return <AdsbPanelHeader {...props} />;
    case "sar":
      return <SarPanelHeader />;
    case "viirs-af":
      return <ViirsAfPanelHeader/>
    case "drone":
      return <DronePanelHeader />;
    default:
      return <Callout title="Invalid feature" />;
  }
};

type PropsWithGoToHandler = FactoryProps & {
  handleGoTo: () => void;
};

const PanelDataFactory = (props: PropsWithGoToHandler) => {
  switch (props.feature.properties?.sensor) {
    case "ais":
      return <AisDataPanel {...props} />;
    case "adsb":
      return <AdsbDataPanel {...props} />;
    case "sar":
      return <SarDataPanel {...props} />;
    case "viirs-af":
      return <ViirsAfDataPanel {...props}/>
    case "drone":
      return <DroneDataPanel {...props} />;
    default:
      return <NonIdealState icon="grid-view" title="No data" />;
  }
};

const FeaturePanel = memo((props: { feature: Feature }) => {
  const map = useMap();

  // Fetch additional feature data
  const { data: ft, isLoading } = useQuery({
    queryKey: [props.feature.id],
    refetchInterval: 15000, // seconds
    queryFn: async () => {
      const ftProps = props.feature.properties;
      const id =
        ftProps?.sensor === "ais"
          ? ftProps.mmsi
          : ftProps?.sensor === "adsb"
          ? ftProps.icao24
          : ftProps?.id;
      return await get(ftProps?.sensor, id);
    },
    initialData: props.feature,
  });

  const { attributes, listeners, setNodeRef } = useDraggable({
    id: ft.id!,
    data: { kind: "feature", feature: ft },
  });

  const handleGoTo = useCallback(() => {
    if (!map.current) return;
    const [longitude, latitude] = (ft.geometry as Point).coordinates;
    map.current.panTo([longitude!, latitude!]);
  }, [map, ft.geometry]);

  return (
    <>
      <div ref={setNodeRef} {...attributes} {...listeners} className="cursor-grab">
        <PanelHeaderFactory feature={ft} isLoading={isLoading} />
      </div>
      <PanelDataFactory feature={ft} isLoading={isLoading} handleGoTo={handleGoTo} />
    </>
  );
});

export default FeaturePanel;
