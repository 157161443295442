import type { LayerProps } from "react-map-gl/maplibre";

export const viirs_af_simple: LayerProps = {
    id: "viirs-af",
    type: "circle",
    filter: ["==", ["get", "sensor"], "viirs-af"],
    minzoom: 4,
    paint: {
        "circle-radius": ["interpolate", ["linear"], ["zoom"], 5, 1.5, 12, 3],
        "circle-color": "#e80c2d",
        "circle-stroke-width": 10,
        "circle-stroke-color": "#f11571",
        "circle-stroke-opacity": ["interpolate", ["linear"], ["zoom"], 8, 0.0, 18, 0.1],
    },
};
