import type { LayerProps } from "react-map-gl/maplibre";

export const sar_simple: LayerProps = {
  id: "sar",
  type: "circle",
  filter: ["==", ["get", "sensor"], "sar"],
  minzoom: 4,
  paint: {
    "circle-radius": ["interpolate", ["linear"], ["zoom"], 5, 1.5, 12, 3],
    "circle-color": "#e83bfb",
    "circle-stroke-width": 10,
    "circle-stroke-color": "#afef61",
    "circle-stroke-opacity": ["interpolate", ["linear"], ["zoom"], 8, 0.0, 18, 0.1],
  },
};
